import React, { useState, useEffect, useCallback, FormEvent, ChangeEvent } from "react";
import { useTranslation } from "react-i18next";
import axios from "axios";
import * as HttpStatus from "http-status-codes";
import validator from "validator";
import { Paper, Box, TextField, FormControl, InputLabel, MenuItem, Select, Typography, Button, RadioGroup, FormControlLabel, Radio } from "@material-ui/core";
import { Redirect } from "react-router";

import { CustomDialog } from "./CustomDialog";
import { addNotification } from "../utils/NotificationHandler";
import { Channel, channelTypes } from "../models/ChannelModel";
import { acrAPI } from "../utils/api";

interface IChannelInformationProps {
  channelId: Number;
  channelTypeInfo: String;                // Display button qualityIndexEnableFromUI depending on channelType
  qualityIndexEnableFromLicense: Boolean; // Display button qualityIndexEnableFromUI depending on License
}

const MAX_WATERMARK_ID_SNAP = 65535;

export function ChannelInformation(channelInformationProps: IChannelInformationProps): React.ReactElement {
  const { t } = useTranslation();

  const [channel, setChannel] = useState<Channel>();
  let isUpdate: boolean = false;
  if (channelInformationProps.channelId && typeof channelInformationProps.channelId === "number") {
    if (channelInformationProps.channelId >= 0) {
      isUpdate = true;
    }
  }

  // Channel information
  const [channelName, setChannelName] = useState("");
  const [channelType, setChannelType] = useState("");
  const [channelEnable, setChannelEnable] = useState(true);
  const [url, setUrl] = useState("");
  const [watermarkIds, setWatermarkIds] = useState<Array<number>>(new Array<number>());
  const [qualityIndexEnableFromUI, setQualityIndexEnableFromUI] = useState(true);
  const [newWatermarkId, setNewWatermarkId] = useState(0);
  const [selectedWatermarkIds, setSelectedWatermarkIds] = useState([0]);
  const [programNumber, setProgramNumber] = useState("");
  const [pid, setPid] = useState("");

  const getChannel = useCallback(async () => {
    await axios
      .get(`/channel/${channelInformationProps.channelId}`)
      .then((response) => {
        const tempChannel: Channel = response.data;
        if (tempChannel) {
          setChannelName(tempChannel.name);
          setUrl(tempChannel.url);
          setChannelEnable(tempChannel.channelEnable);
          setChannelType(tempChannel.channelType);
          setWatermarkIds(tempChannel.watermarkIds);
          setQualityIndexEnableFromUI(tempChannel.qualityIndexEnableFromUI);
          setProgramNumber(tempChannel.programNumber);
          setPid(tempChannel.pid);
        }
        setChannel(tempChannel);
      })
      .catch((e) => console.log("Error while fetching channel:", e));
  }, [channelInformationProps.channelId]);

  useEffect(() => {
    if (isUpdate) {
      getChannel();
    }
  }, [getChannel, isUpdate]);

  const [open, setOpen] = useState(false);
  const openCustomDialog = () => setOpen(true);
  const [redirectToMosaic, setRedirectToMosaic] = useState(false);

  const close = () => setOpen(false);

  function deleteChannel(): void {
    if (channelInformationProps.channelId) {
      axios
        .delete(`/channel/${channelInformationProps.channelId}`)
        .then((res) => {
          if (res && res.status === HttpStatus.OK) {
            //Removing channel ffrom context for an immmediate mosaic refresh
            const tmpChannels = acrAPI.channelsSubject
              .getValue()
              .filter((channel) => channel.id !== channelInformationProps.channelId);
            acrAPI.channelsSubject.next(tmpChannels);
            setRedirectToMosaic(true);
            addNotification(t("channel successfully deleted"));
          } else {
            addNotification(t("failed to delete channel"), "error");
            close();
          }
        })
        .catch((e) => {
          addNotification(t("failed to delete channel"), "error");
          close();
          console.log("Failed to delete channel", e);
        });
    }
  }

  const handleNewWatermarkId = (event: ChangeEvent<HTMLInputElement>) => {
    const enteredWatermarkId = Number(event.target.value);
    if (enteredWatermarkId > MAX_WATERMARK_ID_SNAP) {
      setNewWatermarkId(MAX_WATERMARK_ID_SNAP);
    } else if (enteredWatermarkId < 1) {
      setNewWatermarkId(1);
    } else {
      setNewWatermarkId(enteredWatermarkId);
    }
  };

  const addWatermarkId = () => {
    if (newWatermarkId) {
      const ids = watermarkIds;
      if (ids && watermarkIds && ids.indexOf(newWatermarkId) === -1) {
        setWatermarkIds([...watermarkIds, newWatermarkId]);
      }
    }
  };

  const isCurrentUrlValid = () => {
    if (url.length === 0) {
      return true;
    }
    let valid: boolean = validator.isURL(url, {
      protocols: ["file", "http", "https", "rtsp", "sdp", "udp"],
      require_protocol: true,
      require_valid_protocol: true,
      require_host: false,
      require_tld: false,
    });
    if (valid) {
      return true;
    }
    return false;
  };

  const isProgramNumberValid = () => {
    if (programNumber.length > 5) {
      return false;
    }    
    return true;
  };

  const isPidValid = () => {
    if (programNumber.length > 8) {
      return false;
    }    
    return true;
  };

  const isChannelChange = () => {
    let res: boolean = false;
    if (channel) {
      res =
        channelName !== channel.name ||
        url !== channel.url ||
        channelEnable !== channel.channelEnable ||
        channelType !== channel.channelType ||
        watermarkIds !== channel.watermarkIds ||
        qualityIndexEnableFromUI !== channel.qualityIndexEnableFromUI ||
        programNumber !== channel.programNumber ||
        pid !== channel.pid;
    }
    res = res && isCurrentUrlValid();
    return res;
  };

  const isValidChannel = () => {
    return channelName.length !== 0 && channelType.length !== 0 && isCurrentUrlValid();
  };

  const handleRemoveSelected = () => {
    const newWatermarkId = watermarkIds.filter((watermarkId) => selectedWatermarkIds.indexOf(watermarkId) === -1);
    setWatermarkIds(newWatermarkId);
    setSelectedWatermarkIds([0]);
  };

  const handleChannelEnable = () => {
    setChannelEnable(!channelEnable);
  };

  const handleChangeType = (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
    const value: string = event.target.value as string;
    setChannelType(value);
  };

  const handleChannelName = (event: ChangeEvent<HTMLInputElement>) => {
    setChannelName(event.target.value);
  };

  const handleUrl = (event: ChangeEvent<HTMLInputElement>) => {
    setUrl(event.target.value);
  };

  const handleProgramNumber = (event: ChangeEvent<HTMLInputElement>) => {
    setProgramNumber(event.target.value);
  };

  const handlePid = (event: ChangeEvent<HTMLInputElement>) => {
    setPid(event.target.value);
  };

  const handleQualityIndexEnableFromUI = () => {
    setQualityIndexEnableFromUI(!qualityIndexEnableFromUI);
  };

  const handleCancelOrDelete = () => {
    isUpdate ? openCustomDialog() : setRedirectToMosaic(true);
  };

  const handleSelect = (event: React.MouseEvent) => {
    const value = Number(event.currentTarget.textContent);
    if (selectedWatermarkIds.indexOf(value) > -1) {
      const newSelection: number[] = selectedWatermarkIds.filter((id) => id !== value);
      setSelectedWatermarkIds(newSelection);
    } else {
      const newSelection = [...selectedWatermarkIds, value];
      setSelectedWatermarkIds(newSelection);
    }
  };

  const inputLabel = React.useRef<HTMLLabelElement>(null);

  const handleCreateSubmit = async (event: FormEvent) => {
    event.preventDefault();
    let newChannel = {
      name: channelName.toString(),
      url: url,
      channelEnable: channelEnable,
      channelType: channelType.toString(),
      watermarkIds: watermarkIds,
      qualityIndexEnableFromUI: qualityIndexEnableFromUI,
      programNumber: programNumber,
      pid: pid,
    };
    await axios
      .post(`/channel`, newChannel)
      .then((res) => {
        if (res && res.status === HttpStatus.OK) {
          const createdChannel = new Channel();
          createdChannel.name = newChannel.name;
          createdChannel.channelEnable = newChannel.channelEnable;
          createdChannel.channelType = newChannel.channelType;
          createdChannel.qualityIndexEnableFromUI = newChannel.qualityIndexEnableFromUI;
          createdChannel.programNumber = newChannel.programNumber;
          createdChannel.pid = newChannel.pid;
          const tmpChannels = acrAPI.channelsSubject.getValue();
          tmpChannels.push(createdChannel);
          acrAPI.channelsSubject.next(tmpChannels.sort((a: Channel, b: Channel) => a.name.localeCompare(b.name)));
          addNotification(t("channel successfully created"));
        } else {
          addNotification(t("Failed to create channel"), "error");
        }
      })
      .catch((e) => {
        console.log("Failed to create channel", e);
        addNotification(t("Failed to create channel"), "error");
      });
      try {
        await acrAPI.getStatus();
      } catch (e) {
        console.log(`Error while getting status (init): ${e?.status} (${e?.statusText})`);
      }
      setRedirectToMosaic(true);
  };

  const handleUpdateSubmit = async (event: FormEvent) => {
    event.preventDefault();
    let newChannel = {
      name: channelName.toString(),
      url: url,
      channelEnable: channelEnable,
      channelType: channelType,
      watermarkIds: watermarkIds,
      qualityIndexEnableFromUI: qualityIndexEnableFromUI,
      programNumber: programNumber,
      pid: pid,
    };

    await axios
      .put(`/channel/${channel && channel.id}`, newChannel)
      .then((res) => {
        if (res && res.status === HttpStatus.OK) {
          addNotification(t("channel successfully updated"));
          const tmpChannels = acrAPI.channelsSubject.getValue().map((tmpChannel) => {
            if (channel && tmpChannel.id !== channel.id) {
              return new Channel(tmpChannel);
            }
            tmpChannel.channelEnable = newChannel.channelEnable;
            tmpChannel.channelType = newChannel.channelType;
            tmpChannel.name = newChannel.name;
            tmpChannel.url = newChannel.url;
            tmpChannel.watermarkIds = newChannel.watermarkIds;
            tmpChannel.qualityIndexEnableFromUI = newChannel.qualityIndexEnableFromUI;
            tmpChannel.programNumber = newChannel.programNumber;
            tmpChannel.pid = newChannel.pid;
            return tmpChannel;
          });
          acrAPI.channelsSubject.next(tmpChannels.sort((a: Channel, b: Channel) => a.name.localeCompare(b.name)));
          setRedirectToMosaic(true);
        } else {
          addNotification(t("Failed to update channel"), "error");
          close();
        }
      })
      .catch((e) => {
        console.log("Failed to update channel", e);
        addNotification(t("Failed to update channel"), "error");
      });
  };

  const isCurrentChannelNameValid = () => channelName.length > 20;

  return (
    <Paper elevation={4}>
      {redirectToMosaic && <Redirect to="/mosaic" />}
      {isUpdate && (
        <CustomDialog
          title={t("deleteConfirmationTitle")}
          message={t("deleteConfirmation", {
            //TODO REFACTOR THIS!
            channelName: channelName,
          })}
          action={deleteChannel}
          open={open}
          disableAction={true}
          handleClose={close}
        />
      )}
      <Box marginLeft={2} p={2}>
        <form onSubmit={isUpdate ? handleUpdateSubmit : handleCreateSubmit}>
          <Box display="column">
            <Box display="flex" justifyContent="space-evenly">
              <Box>
                <TextField
                  margin="normal"
                  fullWidth
                  required
                  error={isCurrentChannelNameValid()}
                  helperText={t("channel name limitation")}
                  id="channelName"
                  label={t("channel name")}
                  name="channelName"
                  value={channelName}
                  inputProps={{ maxLength: "20" }}
                  onChange={handleChannelName}
                  autoComplete="channelName"
                  autoFocus
                  data-cy="channel-information-name"
                />
                <InputLabel ref={inputLabel} data-cy="channel-information-type-label" htmlFor="channelTypeSelect">
                  {t("channel type")}
                </InputLabel>
                <FormControl fullWidth required>
                  <Select
                    onChange={handleChangeType}
                    data-cy="channel-information-name-list"
                    name="channelTypeSelect"
                    value={channelType}
                  >
                    {channelTypes.values.map((type: any, index: number) => (
                      <MenuItem
                        key={`channelType${type.id}`}
                        value={type.id}
                        data-cy={`channel-information-name-option-${index}`}
                      >
                        {type.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  error={!isCurrentUrlValid()}
                  helperText={t("channel url limitation")}
                  id="idUrl"
                  label={t("url")}
                  name="url"
                  value={url}
                  autoComplete="url"
                  onChange={handleUrl}
                  data-cy="channel-information-url"
                />
                <TextField
                  margin="normal"
                  fullWidth
                  error={!isProgramNumberValid()}
                  id="idProgramNumber"
                  label={t("program number")}
                  name="programNumber"
                  value={programNumber}
                  autoComplete="programNumber"
                  onChange={handleProgramNumber}
                  data-cy="channel-information-program-number"
                />
                 <TextField
                  margin="normal"
                  fullWidth
                  error={!isPidValid()}
                  id="idPid"
                  label={t("pid")}
                  name="pid"
                  value={pid}
                  autoComplete="pid"
                  onChange={handlePid}
                  data-cy="channel-information-pid"
                />
                {channelInformationProps.channelId > 0 && (
                  <TextField
                    margin="normal"
                    fullWidth
                    disabled
                    label={t("channel id")}
                    defaultValue={channelInformationProps.channelId}
                  />
                )}
              </Box>
              <Box display="flex" flexDirection="column">
                <Typography variant="body2" data-cy="channel-information-expected-wmk-id-list">
                  {t("expected watermark ids")}
                </Typography>
                <Box display="flex">
                  <Box display="flex">
                    <TextField
                      margin="normal"
                      fullWidth
                      type="number"
                      value={newWatermarkId}
                      id="newWatermarkId"
                      name="newWatermarkId"
                      onChange={handleNewWatermarkId}
                      data-cy="channel-information-expected-wmk-id-input"
                    />
                    <Box display="flex" flexDirection="column">
                      <Button
                        style={{
                          width: "1em",
                          height: "3em",
                          margin: "0.5em",
                        }}
                        variant="contained"
                        disabled={watermarkIds.length >= 50}
                        onClick={addWatermarkId}
                      >
                        &gt;
                      </Button>
                      <Button
                        variant="contained"
                        onClick={handleRemoveSelected}
                        style={{
                          width: "1em",
                          height: "3em",
                          margin: "0.5em",
                        }}
                      >
                        &lt;
                      </Button>
                    </Box>
                  </Box>
                  <Select
                    native
                    multiple
                    variant="outlined"
                    value={selectedWatermarkIds}
                    style={{ width: "200px" }}
                    inputProps={{
                      id: "select-multiple-native",
                    }}
                  >
                    {watermarkIds &&
                      watermarkIds.map((watermarkId) => (
                        <option
                          onClick={handleSelect}
                          style={{ width: "3em" }}
                          value={watermarkId}
                          key={`watermarkOption${watermarkId}`}
                        >
                          {watermarkId}
                        </option>
                      ))}
                  </Select>
                </Box>
                
                <Box display="flex" margin-up="big" margin-bottom="normal">
                  <FormControl>
                  <Typography variant="body2" data-cy="channel-information-channel-active">
                        {t("Channel enable")}
                    </Typography>              
                    <RadioGroup row defaultValue="on"
                      value=""
                      aria-labelledby="radio-buttons-group"
                      name="controlled-radio-buttons-group"
                      onChange={handleChannelEnable} >
                      <FormControlLabel value="on" control={<Radio checked={channelEnable} size="medium" color="secondary"/>} label="On" />
                      <FormControlLabel value="off" control={<Radio checked={!channelEnable} size="medium" color="secondary"/>} label="Off" />
                    </RadioGroup>
                    </FormControl>
                  </Box>

                {channelInformationProps.qualityIndexEnableFromLicense && channelInformationProps?.channelTypeInfo !== "am" && (
                  <Box display="flex" margin-up="big" margin-bottom="normal">
                    <FormControl>
                    <Typography variant="body2" data-cy="channel-information-quality-index-enable-fromUI">
                        {t("quality index enable")}
                    </Typography>              
                    <RadioGroup row defaultValue="on"
                      value=""
                      aria-labelledby="radio-buttons-group"
                      name="controlled-radio-buttons-group"
                      onChange={handleQualityIndexEnableFromUI} >
                      <FormControlLabel value="on" control={<Radio checked={qualityIndexEnableFromUI} size="medium" color="secondary"/>} label="On" />
                      <FormControlLabel value="off" control={<Radio checked={!qualityIndexEnableFromUI} size="medium" color="secondary"/>} label="Off" />
                    </RadioGroup>
                    </FormControl>
                  </Box>
                )}
              </Box>
            </Box>
            <Box display="flex" justifyContent="space-around">
              {isUpdate ? (
                <Button type="submit" variant="contained" color="primary" disabled={!isChannelChange()}>
                  {t("update")}
                </Button>
              ) : (
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={!isValidChannel()}
                  data-cy="channel-information-create-button"
                >
                  {t("create")}
                </Button>
              )}
              <Button
                color="secondary"
                variant="contained"
                onClick={handleCancelOrDelete}
                data-cy={isUpdate ? "channel-information-delete-button" : "channel-information-cancel-button"}
              >
                {isUpdate ? t("delete") : t("cancel")}
              </Button>
            </Box>
          </Box>
        </form>
      </Box>
    </Paper>
  );
}
