export class WatermarkLog {
  id: number = 0;
  watermarkId: number = 0;
  confidence: number = -1;
  payloadType: string = '';
  channelId: number = 0;
  channelName: string = '';
  timestamp: Date = new Date();
  start: Date = new Date();
  stop: Date = new Date();
}
