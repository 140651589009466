import i18n from "i18next";
import { initReactI18next } from "react-i18next";
const resources = require("./localization.json");

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: "en",
    keySeparator: false, // we do not use keys in form messages.welcome
    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

export function translate(cell: string, options: object = {}): string {
  return i18n.t(cell, options);
}

export default i18n;
