import React from "react";

import { store } from "react-notifications-component";
import { Notification } from "../components/Notification";

export function addNotification(message: string, className: string = "success"): void {
  const id = store.addNotification({
    id: Date.now().toFixed(),
    container: "bottom-right",
    insert: "top",
    dismiss: {
      duration: 2000,
      onScreen: true
    },
    content: <Notification className={className} message={message} />
  });
  setTimeout(() => store.removeNotification(id), 5000);
}
