import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Button } from "@material-ui/core";

import { translate } from "i18n";
interface ICustomDialogProps {
  title: string;
  message: string;
  action: () => void;
  handleClose: () => void;
  open: boolean;
  disableAction?: boolean;
  confirmLabel?: string;
}

export class CustomDialog extends React.Component<ICustomDialogProps> {
  public state = { disabled: false };
  private triggerAction(): void {
    if (this.props.disableAction) {
      this.setState({ disabled: true });
    }
    this.props.action();
  }
  private triggerClose(): void {
    this.setState({ disabled: false });
    this.props.handleClose();
  }
  public render(): JSX.Element {
    return (
      <Dialog
        open={this.props.open}
        onClose={this.props.handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">{this.props.title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">{this.props.message}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => this.triggerClose()} color="primary" data-cy="custom-dialog-cancel-button">
            {translate("cancel")}
          </Button>
          <Button
            onClick={() => this.triggerAction()}
            color="primary"
            data-cy="custom-dialog-ok-button"
            autoFocus
            disabled={this.state.disabled}>
            {translate(this.props.confirmLabel ? this.props.confirmLabel : "delete")}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}
