import React from "react";
import { Box, Typography, Link } from "@material-ui/core";
import { Subscription } from "rxjs";

import { KantarLogo } from "./KantarLogo";
import Clock from "./Clock";
import { acrAPI } from "../../utils/api";

import { translate } from "../../i18n";

export class Footer extends React.Component {
  private subscribtions: Subscription = new Subscription();
  public state = { version: "..." };

  constructor(props: any) {
    super(props);
    this.updateVersion = this.updateVersion.bind(this);
  }

  public componentDidMount(): void {
    this.subscribtions.add(acrAPI.versionSubject.subscribe(this.updateVersion));
  }

  public componentWillUnmount(): void {
    this.subscribtions.unsubscribe();
  }

  private updateVersion(value: string): void {
    this.setState({ version: value });
  }

  public render(): JSX.Element {
    return (
      <Box
        display="flex"
        justifyContent="space-between"
        boxShadow={"0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)"}>
        <Box style={{ width: "33%" }} display="flex" alignItems="center">
          <Link target="_blank" href="https://www.kantar.com/expertise/audience-measurement">
            <KantarLogo />
          </Link>
          <Typography data-cy="footer-copyright-label" variant="body2" color="textSecondary">
            {translate("copyright")}
          </Typography>
        </Box>
        <Box alignItems="center" display="flex" padding="0 8px">
          <Box flexGrow={1}>
            <Typography variant="body2" data-cy="footer-version-label" color="textSecondary">
              {translate("softwareVersion", { version: this.state.version })}
            </Typography>
          </Box>
        </Box>
        <Box style={{ width: "33%" }} justifyContent="flex-end" marginRight={1} display="flex">
          <Clock />
        </Box>
      </Box>
    );
  }
}
