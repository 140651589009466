import React from "react";
import { useTranslation } from "react-i18next";

import { TableSortLabel, Table, Box, Typography } from "@material-ui/core";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

import { ILogModule } from "acr-common-models";

import { OrderType, ISoftwareLogRequestParameters } from "../../pages/SoftwareLogs";
import { SoftwareLog } from "../../models/SoftwareLogModel";

import { LED_COLORS } from "../../utils/colors";
import { getFormatedUtcDate } from "../../utils/date";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      overflowX: "auto",
    },
    bodyFlow: {
      overflow: "auto",
      width: "100%",
      height: "10%",
    },
    fixedHead: { zIndex: 1 },
    visuallyHidden: {
      border: 0,
      clip: "rect(0 0 0 0)",
      height: 1,
      margin: -1,
      overflow: "hidden",
      padding: 0,
      position: "absolute",
      top: 20,
      width: 1,
    },
    tableWrapper: {
      overflow: "auto",
    },
  })
);

interface ISoftwareLogTableProps {
  softwareLogs: Array<SoftwareLog>;
  getSotwareLogs: (param: ISoftwareLogRequestParameters) => void;
  orderBy: keyof SoftwareLog;
  orderType: OrderType;
  handleOrderTypeUpdate: (orderType: OrderType) => void;
  handleOrderByUpdate: (orderBy: keyof SoftwareLog) => void;
  showChannelName?: boolean;
  loading: boolean;
  containerHeight: number;
  logModules: ILogModule[];
}

export function SoftwareLogTable(softwareLogTableProps: ISoftwareLogTableProps): React.ReactElement {
  const { t } = useTranslation();
  const classes = useStyles();

  const LOG_LEVELS = ["DEBUG", "INFO", "WARNING", "ERROR"];
  const LOG_BG_COLORS = ["#CCC", "#FFF", LED_COLORS.WARNING.BACKGROUND.ENABLED, LED_COLORS.ERROR.BACKGROUND.ENABLED];
  const LOG_FONT_COLORS = ["#000", "#000", LED_COLORS.WARNING.FONT.ENABLED, LED_COLORS.ERROR.FONT.ENABLED];

  const createSortHandler = (property: keyof SoftwareLog) => (event: React.MouseEvent<unknown>) => {
    softwareLogTableProps.handleOrderTypeUpdate(softwareLogTableProps.orderType === "desc" ? "asc" : "desc");
    softwareLogTableProps.handleOrderByUpdate(property);
  };

  function tableCell(property: keyof SoftwareLog, width: string = "", label: string = ""): React.ReactElement {
    return (
      <TableCell component={"th" as any} style={{ width: width }} sortDirection={softwareLogTableProps.orderType}>
        <TableSortLabel
          active={softwareLogTableProps.orderBy === property}
          direction={softwareLogTableProps.orderType}
          onClick={createSortHandler(property)}>
          {t(label.length > 0 ? label : property)}
          {softwareLogTableProps.orderBy === property ? (
            <span className={classes.visuallyHidden}>
              {softwareLogTableProps.orderType === "desc" ? "sorted descending" : "sorted ascending"}
            </span>
          ) : null}
        </TableSortLabel>
      </TableCell>
    );
  }
  return (
    <div className={classes.tableWrapper} style={{ maxHeight: softwareLogTableProps.containerHeight }}>
      <Table stickyHeader>
        <TableHead>
          <TableRow className={classes.fixedHead}>
            {tableCell("date", "165px")}
            {tableCell("logLevel", "80px")}
            {softwareLogTableProps.showChannelName && tableCell("moduleType", "100px", "Category")}
            {softwareLogTableProps.showChannelName && tableCell("channelName", "275px")}
            {tableCell("description")}
          </TableRow>
        </TableHead>
        <TableBody className={classes.bodyFlow}>
          {softwareLogTableProps.softwareLogs.length > 0 ? (
            softwareLogTableProps.softwareLogs.map((log: SoftwareLog) => (
              <TableRow
                key={log.id}
                style={{
                  backgroundColor: LOG_BG_COLORS[log.logLevel],
                  color: LOG_FONT_COLORS[log.logLevel],
                }}>
                <TableCell
                  style={{
                    color: LOG_FONT_COLORS[log.logLevel],
                    minWidth: "150px",
                  }}>
                  {getFormatedUtcDate(log.date)}
                </TableCell>
                <TableCell
                  style={{
                    color: LOG_FONT_COLORS[log.logLevel],
                  }}>
                  {LOG_LEVELS[log.logLevel]}
                </TableCell>
                {softwareLogTableProps.showChannelName && (
                  <TableCell
                    style={{
                      color: LOG_FONT_COLORS[log.logLevel],
                    }}>
                    {softwareLogTableProps.logModules.find((logModule) => logModule.label === log.moduleType)?.title}
                  </TableCell>
                )}
                {softwareLogTableProps.showChannelName && (
                  <TableCell
                    style={{
                      color: LOG_FONT_COLORS[log.logLevel],
                    }}>
                    {log.channelName}
                  </TableCell>
                )}
                <TableCell
                  style={{
                    color: LOG_FONT_COLORS[log.logLevel],
                  }}
                  component={"td" as any}
                  scope="row">
                  {log.description}
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={5}>
                <Box display="flex" justifyContent="center" alignItems="center" m={5} p={5}>
                  <Typography variant="h4">{t("no software log found")}</Typography>
                </Box>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </div>
  );
}
