import React from "react";

import { Box, Tabs, Tab } from "@material-ui/core";
import GridOn from "@material-ui/icons/GridOn";
import BuildIcon from "@material-ui/icons/Build";
import DescriptionIcon from "@material-ui/icons/Description";
import Settings from "@material-ui/icons/Settings";

import { Link } from "react-router-dom";
import { translate } from "../../i18n";

export class ACRMenu extends React.Component {
  private getTabId = () =>
    window.location.href.indexOf("mosaic") > -1
      ? 0
      : window.location.href.indexOf("logs") > -1
      ? 1
      : window.location.href.indexOf("settings") > -1
      ? 2
      : window.location.href.indexOf("maintenance") > -1
      ? 3
      : 0;

  private handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    if (newValue === 0) {
      window.history.pushState({}, "/mosaic");
      this.setState({ tab: newValue });
    }
    if (newValue === 1) {
      window.history.pushState({}, "/logs");
      this.setState({ tab: newValue });
    }
    if (newValue === 2) {
      window.history.pushState({}, "/settings");
      this.setState({ tab: newValue });
    }
    if (newValue === 3) {
      window.history.pushState({}, "/maintenance");
      this.setState({ tab: newValue });
    }
  };

  private a11yProps(index: number): Object {
    const id = `scrollable-force-tab-${index}`;
    return {
      "id": id,
      "aria-controls": id
    };
  }
  public render(): JSX.Element {
    return (
      <Box display="flex" height="75px">
        <Tabs
          value={this.getTabId()}
          onChange={this.handleChange}
          indicatorColor="primary"
          textColor="primary"
          style={{ overflow: "unset!important" as any }}
          scrollButtons="off">
          <Tab
            label={translate("mosaic")}
            icon={<GridOn />}
            component={Link}
            to="/mosaic"
            style={{ minWidth: "75px" }}
            {...this.a11yProps(0)}
            data-cy="acr-menu-mosaic"
          />
          <Tab
            label={translate("logs")}
            icon={<DescriptionIcon />}
            component={Link}
            style={{ minWidth: "75px" }}
            to="/logs"
            {...this.a11yProps(1)}
            data-cy="acr-menu-logs"
          />
          <Tab
            label={translate("settings")}
            icon={<Settings />}
            component={Link}
            style={{ minWidth: "75px" }}
            to="/settings"
            {...this.a11yProps(2)}
            data-cy="acr-menu-settings"
          />
          <Tab
            label={translate("maintenance")}
            icon={<BuildIcon />}
            component={Link}
            style={{ minWidth: "75px" }}
            to="/maintenance"
            {...this.a11yProps(3)}
            data-cy="acr-menu-maintenance"
          />
        </Tabs>
      </Box>
    );
  }
}
