import React from "react";
import { Typography } from "@material-ui/core";
import { translate } from "i18n";

interface ITrapReceiverFormErrorsProps {
  errors?: any;
  conflict: boolean;
}

export class TrapReceiverFormErrors extends React.Component<ITrapReceiverFormErrorsProps> {
  public render(): JSX.Element {
    return (
      <>
        {this.props.errors?.ipAddress && (
          <Typography data-cy="snmp-settings-trap-receiver-ipAddress-error" color="error">
            {translate(`Bad snmp ipAddress format`)}
          </Typography>
        )}
        {this.props.errors?.port && (
          <Typography data-cy="snmp-settings-trap-receiver-port-error" color="error">
            {" "}
            {translate(`Bad snmp port format`)}
          </Typography>
        )}
        {this.props.conflict && (
          <Typography data-cy="snmp-settings-trap-receiver-conflict-error" color="error">
            {translate(`A trap receiver with same IP and port already exists`)}
          </Typography>
        )}
      </>
    );
  }
}
