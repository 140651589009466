import React from "react";
import Input from "@material-ui/core/Input";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import ListItemText from "@material-ui/core/ListItemText";
import Select from "@material-ui/core/Select";
import Checkbox from "@material-ui/core/Checkbox";
import { ILogModule } from "acr-common-models";

import { Box } from "@material-ui/core";
import { translate } from "../../../i18n";

interface ILogModulesFilterProps {
  logModules: ILogModule[];
  categoryName: string[];
  onChange?: (event: React.ChangeEvent<{ value: unknown }>) => void;
  onClose?: (event: React.ChangeEvent<{}>) => void;
}

export class LogModulesFilter extends React.Component<ILogModulesFilterProps> {
  public ITEM_HEIGHT = 52;
  public ITEM_PADDING_TOP = 4;
  public menuProps = {
    PaperProps: {
      style: {
        maxHeight: this.ITEM_HEIGHT * 5.5 + this.ITEM_PADDING_TOP,
        minWidth: 250,
        maxWidth: 250,
      },
    },
  };
  public render(): JSX.Element {
    return (
      <Box display="flex" alignItems="center">
        <FormControl>
          <Select
            data-cy="categories-filter"
            multiple
            value={this.props.categoryName}
            onChange={this.props.onChange}
            onClose={this.props.onClose}
            input={<Input style={{ color: "rgba(0, 0, 0, 0.54)" }} />}
            displayEmpty={true}
            renderValue={(_) => translate("Categories")}
            MenuProps={this.menuProps}>
            {this.props.logModules.map((logModule, index) => (
              <MenuItem key={logModule.label} value={logModule.title}>
                <Checkbox
                  data-cy={`category-item-${index}`}
                  color="primary"
                  checked={this.props.categoryName.indexOf(logModule.title) > -1}
                />
                <ListItemText primary={logModule.title} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
    );
  }
}
