import React from "react";
import axios from "axios";
import { Button, Box } from "@material-ui/core";
import GetAppIcon from "@material-ui/icons/GetApp";
import { SoftwareLog } from "../../../models/SoftwareLogModel";
import { WithTranslation, withTranslation } from "react-i18next";
import { addNotification } from "../../../utils/NotificationHandler";
import { moduleTypes } from "acr-common-models";

interface IDownloadProps extends WithTranslation {
  logType: "watermarkLog" | "softwareLog" | "qualityIndexLog";
  channelId?: number;
  logLevel?: number;
  orderType?: "desc" | "asc";
  orderBy?: keyof SoftwareLog;
  encodedSearchQuery?: string;
  categoryName?: string[];
}

class Download extends React.Component<IDownloadProps> {
  private aRef: React.RefObject<HTMLAnchorElement> = React.createRef();
  private disabled: boolean = false;

  private buildHref(): string {
    const props = this.props as IDownloadProps;
    let params = [];
    if (props.channelId) {
      params.push(`channelId=${props.channelId}`);
    }
    if (props.logLevel) {
      params.push(`level=${props.logLevel}`);
    }
    if (props.encodedSearchQuery && props.encodedSearchQuery.length > 0) {
      params.push(`searchQuery=${props.encodedSearchQuery}`);
    }
    if (props.orderBy) {
      params.push(`orderBy=${props.orderBy}`);
    }
    if (props.orderType) {
      params.push(`orderType=${props.orderType}`);
    }
    if (props.categoryName) {
      const categoryList: Array<number | undefined> = props.categoryName.map(
        (category) => moduleTypes.find((moduleType) => category === moduleType.title)?.id
      );
      for (var i=0; i< categoryList.length; i++) {
        params.push("moduleTypes="+categoryList[i]);
      }
    }
    params.push(`authorization=Bearer ${localStorage.getItem("access_token")}`);
    let href = `${axios.defaults.baseURL}/${props.logType}/download`;
    if (params.length) {
      href += "?" + params.join("&");
    }
    href = href.replace(":3000/api", ":3100/api"); // FCTODO remove when dev proxy supports streaming
    return href;
  }

  private onClick = async () => {
    const { t } = this.props;
    const element = this.aRef.current;
    if (!element) {
      return;
    }
    // TODO disable the download button when no log are displayed (wrong filter, or empty category, or...)
    if (this.props.categoryName && this.props.categoryName.length === 0) {
      // No category: no log
      return;
    }
    element.href = this.buildHref();
    element.click();
    this.disabled = true;
    addNotification(t("Download in progress"));
    setTimeout(() => {
      this.disabled = false;
    }, 5000);
  };

  public render(): React.ReactElement {
    const { t } = this.props;
    return (
      <Box style={{ margin: "18px 2em 0 auto", paddingTop: "16px" }}>
        <Button data-cy="download-button" variant="contained" color="primary" onClick={this.onClick} disabled={this.disabled}>
          <GetAppIcon />
          {t("Download")}
        </Button>
        <a ref={this.aRef} href="./" style={{ display: "none" }} target="_blank" download="error">
          Link
        </a>
      </Box>
    );
  }
}

export const translatedDownload = withTranslation()(Download);
export { translatedDownload as Download };
