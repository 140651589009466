import axios from "axios";
import { BehaviorSubject } from "rxjs";
import * as HttpStatus from "http-status-codes";

class LoginService {
  private login: string = "";
  private static instance: LoginService;

  public static getInstance(): LoginService {
    if (this.instance === undefined) {
      this.instance = new this();
    }
    return this.instance;
  }
  public isLoggedSubject = new BehaviorSubject<boolean>(false);

  public init(): void {
    this.isLoggedSubject.next(!!localStorage.getItem("access_token"));
  }

  public getLogin = () => this.login;

  public authenticate = async (data: any) => {
    return axios
      .post(`/user/login`, data)
      .then((response) => {
        if (response.status === 200 && response.data.token) {
          const { token, language } = response.data;
          this.login = data.login;
          localStorage.setItem("access_token", token);
          localStorage.setItem("login", this.login);
          localStorage.setItem("language", language);
          this.isLoggedSubject.next(true);
          window.location.href = "/mosaic";
        }
      })
      .catch((e) => {
        if (e.status === HttpStatus.UNAUTHORIZED) {
          throw e;
        }
        this.login = "";
        return false;
      });
  };
  public updatePassword = async (oldPassword: string, newPassword: string) => {
    try {
      const data = {
        login: localStorage.getItem("login"),
        oldPassword,
        newPassword,
      };
      return await axios.patch(`/user/password`, data);
    } catch (e) {
      console.log("Error while updating password:", e);
      return false;
    }
  };

  public logout = () => {
    console.log("LOG OUT");
    this.isLoggedSubject.next(false);
    localStorage.removeItem("login");
    localStorage.removeItem("access_token");
    this.login = "";
  };

  public verifyAuthentification = () => {
    try {
      const token: string | null = localStorage.getItem("access_token");
      if (token) {
        return true;
      }
      return false;
    } catch (e) {
      console.log("Token check failed:", e);
      this.logout();
    }

    return false;
  };
}

export const loginService = LoginService.getInstance();
