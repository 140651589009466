import React from "react";
import { Box, FormControl, InputLabel, Select, MenuItem } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { dropDownStyles } from "../../../utils/theme";

interface ILogLevelProps {
  handleLogLevelChange: (event: React.ChangeEvent<{ name?: string; value: unknown }>) => void;
  logLevel: number;
}

export function LogLevel(logLevelProps: ILogLevelProps): React.ReactElement {
  const { t } = useTranslation();
  const classes = dropDownStyles();
  return (
    <Box display="flex" alignItems="center" className={classes.container}>
      <FormControl className={classes.formControl}>
        <InputLabel className={classes.miniLabel} data-cy="log-level-label" htmlFor="logLevel-select">
          {t("log level")}
        </InputLabel>
        <Select
          onChange={logLevelProps.handleLogLevelChange}
          name="logLevel"
          value={logLevelProps.logLevel}
          required
          inputProps={{
            name: "logLevel",
            id: "logLevel-select"
          }}>
          <MenuItem value={"0"}>DEBUG</MenuItem>
          <MenuItem value={"1"} selected>
            INFO
          </MenuItem>
          <MenuItem value={"2"}>WARNING</MenuItem>
          <MenuItem value={"3"}>ERROR</MenuItem>
        </Select>
      </FormControl>
    </Box>
  );
}
