import React from "react";
import Tooltip from "@material-ui/core/Tooltip";
import { Led } from "./Led";
import { LED_COLORS } from "../../utils/colors";
import { acrAPI } from "../../utils/api";
import { License } from "../../models/LicenseModel";
import { getFormatedUtcDate } from "../../utils/date";
import { translate } from "../../i18n";

export class ServerLed extends React.Component {
  public state = {
    serverConnexionStatus: null,
    serverLedColor: LED_COLORS.WAITING,
    tooltipMessage: ""
  };

  constructor(props: any) {
    super(props);
    this.updateStatus = this.updateStatus.bind(this);
    this.updateConnexionStatus = this.updateConnexionStatus.bind(this);
  }

  public componentDidMount(): void {
    acrAPI.licenseSubject.subscribe(this.updateStatus);
    acrAPI.connexionStatusSubject.subscribe(this.updateConnexionStatus);
  }

  public componentWillUnmount(): void {
    acrAPI.licenseSubject.unsubscribe();
    acrAPI.connexionStatusSubject.unsubscribe();
  }

  private updateConnexionStatus(status: boolean | null): void {
    this.setState({ serverConnexionStatus: status });
    //console.log(`[${this.name}] updateConnexionStatus status:${status}`); // TODO remove
  }

  private updateStatus(license: License): void {
    //console.log(`[${this.name}] updateStatus license status:${license.status} license date:${license.expirationDate}`); // TODO remove

    if (this.state.serverConnexionStatus == null || license.status == null) {
      this.setState({ serverLedColor: LED_COLORS.WAITING });
    } else if (!this.state.serverConnexionStatus || license.isError()) {
      this.setState({
        serverLedColor: LED_COLORS.ERROR,
        tooltipMessage: translate("license invalid", { expirationDate: license.expirationDate })
      });
    } else if (license.isWarning()) {
      this.setState({
        serverLedColor: LED_COLORS.WARNING,
        tooltipMessage: `${translate("license almost expired")} (${getFormatedUtcDate(license.expirationDate)})`
      });
    } else if (license.isOk()) {
      this.setState({ serverLedColor: LED_COLORS.OK });
    }
  }

  public render(): JSX.Element {
    //console.log(`[${this.name}] render`); // TODO remove
    return (
      <Tooltip disableFocusListener title={this.state?.tooltipMessage} placement="bottom">
        <span>
          <Led dataCy="server-status-led" colors={this.state.serverLedColor} />
        </span>
      </Tooltip>
    );
  }
}
