import React from "react";

import { Box, Typography } from "@material-ui/core";
import { translate } from "i18n";
import { SnmpConfigurationModel } from "acr-common-models";
import { acrAPI } from "utils/api";
import { SnmpGlobalConfiguration } from "./SNMP/CommunityName";

import { Subscription } from "rxjs";
import { TrapReceiversPanel } from "./SNMP/TrapReceiversPanel";

import { EmailConfigurationModel } from "acr-common-models";
import { SmtpConfig } from "./EMAIL/SmtpConfig";
import { EmailReceiversPanel } from "./EMAIL/EmailReceiversPanel";


export class Alerting extends React.Component {
  private subscribtions: Subscription = new Subscription();

  public state = { loading: true, loadingEmail: true, snmpConfigurationModel: new SnmpConfigurationModel(), emailConfigurationModel: new EmailConfigurationModel() };

  constructor(props: any) {
    super(props);
    this.updateConfiguration = this.updateConfiguration.bind(this);
    this.updateConfigurationEmail = this.updateConfigurationEmail.bind(this);
  }

  public componentDidMount(): void {
    this.subscribtions.add(acrAPI.communityNameSubject.subscribe(this.updateConfiguration));
    acrAPI.getSnmpConfiguration();

    this.subscribtions.add(acrAPI.smtpConfigSubject.subscribe(this.updateConfigurationEmail));
    acrAPI.getEmailConfiguration();
  }

  public componentWillUnmount(): void {
    this.subscribtions.unsubscribe();
  }

  private updateConfiguration(): void {
    this.setState({ loading: false });
  }

  private updateConfigurationEmail(): void {
    this.setState({ loadingEmail: false });
  }


  public render(): JSX.Element {
    return (
    <Box display="flex" flexDirection="row" style={{ width: "100%" }} justifyContent="space-between">
      <Box display="flex" flexDirection="column" marginLeft={2} p={2}>
        <Typography variant="h4" style={{ fontWeight: 'bold', marginBottom: "1em"}}  data-cy="settings-snmp-label">
          {translate("SNMP")}
        </Typography>
        {this.state.loading ? (
          <Typography variant="h5">{translate("Loading")}</Typography>
        ) : (
          <>
            <SnmpGlobalConfiguration />
            <TrapReceiversPanel />
          </>
        )}
      </Box>
      
      <Box display="flex" flexDirection="column" marginLeft={2} p={2}>
      <Typography variant="h4" style={{ fontWeight: 'bold', marginBottom: "1em"}} data-cy="settings-email-label">
        {translate("EMAIL")}
      </Typography>
      {this.state.loadingEmail ? (
        <Typography variant="h5">{translate("Loading")}</Typography>
      ) : (
        <>
          <SmtpConfig />
          <EmailReceiversPanel />
        </>
      )}
      </Box>
    </Box>
    );
  }
}
