export const acrMonitorColors = {
  GREY_ENABLED: "rgb(125,125,125)",
  GREY_DISABLED: "rgba(125,125,125,0.3)",
  GREY_LIGHT_ENABLED: "rgb(200,200,200)",
  GREY_LIGHT_DISABLED: "rgba(200,200,200,0.3)",
  GREEN_ENABLED: "rgb(0,182,0)",
  GREEN_DISABLED: "rgba(0,182,0,0.3)",
  RED_ENABLED: "rgb(255,0,0)",
  RED_DISABLED: "rgba(255,0,0,0.2)",
  YELLOW_ENABLED: "rgb(254,219,0)",
  YELLOW_DISABLED: "rgba(254,219,0,0.2)",
  PRIMARY_MAIN: "rgb(0,91,129)",
  PRIMARY_DARK: "rgb(0,182,255)",
  SECONDARY_MAIN: "rgb(237,102,102)",
  SECONDARY_DARK: "rgb(255,0,0)"
};

export interface ILedColors {
  BACKGROUND: {
    DISABLED: string;
    ENABLED: string;
  };
  FONT: {
    DISABLED: string;
    ENABLED: string;
  };
}

export const LED_COLORS = {
  STOP: {
    BACKGROUND: {
      DISABLED: acrMonitorColors.GREY_DISABLED,
      ENABLED: acrMonitorColors.GREY_ENABLED
    },
    FONT: {
      ENABLED: "#000",
      DISABLED: "#000"
    }
  } as ILedColors,
  WAITING: {
    BACKGROUND: {
      DISABLED: acrMonitorColors.GREY_LIGHT_DISABLED,
      ENABLED: acrMonitorColors.GREY_LIGHT_ENABLED
    },
    FONT: {
      ENABLED: "#000",
      DISABLED: "#000"
    }
  } as ILedColors,
  OK: {
    BACKGROUND: {
      DISABLED: acrMonitorColors.GREEN_DISABLED,
      ENABLED: acrMonitorColors.GREEN_ENABLED
    },
    FONT: {
      ENABLED: "#000",
      DISABLED: "#000"
    }
  } as ILedColors,
  WARNING: {
    BACKGROUND: {
      DISABLED: acrMonitorColors.YELLOW_DISABLED,
      ENABLED: acrMonitorColors.YELLOW_ENABLED
    },
    FONT: {
      ENABLED: "#000",
      DISABLED: "#000"
    }
  } as ILedColors,
  ERROR: {
    BACKGROUND: {
      DISABLED: acrMonitorColors.RED_DISABLED,
      ENABLED: acrMonitorColors.RED_ENABLED
    },
    FONT: {
      ENABLED: "#FFF",
      DISABLED: "#FFF"
    }
  } as ILedColors
};
