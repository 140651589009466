import React from "react";

import Paper from "@material-ui/core/Paper";
import { Box, AppBar, Tabs, Tab } from "@material-ui/core";

import { Alerting } from "components/Settings/Alerting";

import { LanguageSwitcher } from "components/Settings/LanguageSwitcher";
import { PasswordUpdater } from "components/Settings/PasswordUpdater";
import { WatermarkingConfiguration } from "components/Settings/WatermarkingConfiguration";
import { SimpleTabPanel } from "../components/Header/SimpleTabPanel";
import { translate } from "i18n";

export class Settings extends React.Component {
  public state = { tab: 0 };

  constructor(props: any) {
    super(props);
    window.onpopstate = () => {
      window.location.href = "/settings";
    };
  }
  //history manangement, we push this page in order to go back to the mosaic page
  //Note: we can acess to this current page only if we are on the mosaic and if we click on the + button
  private a11yProps(index: any): object {
    return {
      "id": `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  private tabChange = (event: React.ChangeEvent<{}>, tabId: number) => {
    this.setState({ tab: tabId });
  };

  public render(): JSX.Element {
    return (
      <>
        <Box style={{ width: "100%", padding: "2em" }}>
          <Paper elevation={4}>
            <Box>
              <AppBar position="static">
                <Tabs value={this.state.tab} onChange={this.tabChange}>
                  <Tab data-cy="settings-password-update-tab" label={translate("password update")} {...this.a11yProps(0)} />
                  <Tab data-cy="settings-language-tab" label={translate("language")} {...this.a11yProps(1)} />
                  <Tab data-cy="settings-alerting-tab" label={translate("alerting")} {...this.a11yProps(2)} />
                  <Tab data-cy="settings-watermarking-tab" label={translate("watermarking")} {...this.a11yProps(3)} />
                </Tabs>
              </AppBar>
            </Box>

            <SimpleTabPanel value={this.state.tab} index={0}>
              <PasswordUpdater />
            </SimpleTabPanel>
            <SimpleTabPanel value={this.state.tab} index={1}>
              <LanguageSwitcher />
            </SimpleTabPanel>
            <SimpleTabPanel value={this.state.tab} index={2}>
              <Alerting />
            </SimpleTabPanel>
            <SimpleTabPanel value={this.state.tab} index={3}>
              <WatermarkingConfiguration />
            </SimpleTabPanel>
          </Paper>
        </Box>
      </>
    );
  }
}
