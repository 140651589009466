import React from "react";
import { ChannelLed } from "./ChannelLed";
import { LED_COLORS } from "../../utils/colors";
import Typography from "@material-ui/core/Typography";
import { Box } from "@material-ui/core";
import { translate } from "../../i18n";

interface IChannelsStateProps {
  channelOkCount: number;
  channelWarningCount: number;
  channelErrorCount: number;
  channelStopCount: number;
}
export class ChannelsState extends React.Component<IChannelsStateProps> {
  public render(): JSX.Element {
    return (
      <Box display="flex" flexDirection="row" alignItems="center">
        <Typography style={{ width: "65px" }} data-cy="channel-status-label" color="textSecondary">
          {translate("Channels status")}
        </Typography>
        <Box width={160} justifyContent="space-evenly" display="flex">
          <ChannelLed dataCy="channels-status-led-ok" nbActiveItems={this.props.channelOkCount} colors={LED_COLORS.OK} />
          <ChannelLed
            dataCy="channels-status-led-warning"
            nbActiveItems={this.props.channelWarningCount}
            colors={LED_COLORS.WARNING}
          />
          <ChannelLed dataCy="channels-status-led-error" nbActiveItems={this.props.channelErrorCount} colors={LED_COLORS.ERROR} />
          <ChannelLed dataCy="channels-status-led-stop" nbActiveItems={this.props.channelStopCount} colors={LED_COLORS.STOP} />
        </Box>
      </Box>
    );
  }
}
