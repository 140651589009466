import React, { KeyboardEvent, ChangeEvent } from "react";
import { Box, Button, FormControl, InputLabel, TextField } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import SearchIcon from "@material-ui/icons/Search";
import { createStyles, makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(_ =>
  createStyles({
    container: {
      padding: "8px 0",
      margin: "8px 0 0 auto"
    },
    textField: {
      width: "300px",
      paddingTop: "2px"
    },
    button: { margin: "1rem" }
  })
);

interface ISearchProps {
  searchQuery: string;
  onSearchQueryChange: (event: ChangeEvent<HTMLInputElement>) => void;
  onSearchKeyUp: (event: KeyboardEvent<HTMLDivElement>) => void;
  refreshSearch: () => void;
}

export function Search(searchProps: ISearchProps): React.ReactElement {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <Box display="flex" justifyContent="flex-end" className={classes.container}>
      <FormControl>
        {searchProps.searchQuery.length === 0 && <InputLabel htmlFor="logLevel-select">{t("search")}</InputLabel>}
        <TextField
          margin="normal"
          id="url"
          name="url"
          className={classes.textField}
          value={searchProps.searchQuery}
          onChange={searchProps.onSearchQueryChange}
          onKeyUp={searchProps.onSearchKeyUp}
        />
      </FormControl>
      <Button
        variant="contained"
        color="primary"
        className={classes.button}
        data-cy="search-button"
        onClick={searchProps.refreshSearch}>
        <SearchIcon fontSize="large" />
      </Button>
    </Box>
  );
}
