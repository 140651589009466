import React from "react";
import { Box, Typography } from "@material-ui/core";
import { Subscription } from "rxjs";
import { format } from "date-fns";
import { License } from "../../models/LicenseModel";
import { acrAPI } from "../../utils/api";
import { LICENSE_STATUS } from "../../ACRMonitor";
import { translate } from "../../i18n";

export class LicenseInformation extends React.Component {
  private subscribtions: Subscription = new Subscription();

  public state = { acrLicense: new License() };
  constructor(props: any) {
    super(props);
    this.updateLicense = this.updateLicense.bind(this);
  }
  public componentDidMount(): void {
    this.subscribtions.add(acrAPI.licenseSubject.subscribe(this.updateLicense));
  }
  public componentWillUnmount(): void {
    this.subscribtions.unsubscribe();
  }

  private updateLicense(value: License): void {
    this.setState({ acrLicense: value });
  }
  public render(): JSX.Element {
    let licenseMessage = "";
    let licenseValidity = "";

    if (this.state.acrLicense.status === LICENSE_STATUS.OK) {
      licenseMessage = "license ok";
    } else if (this.state.acrLicense.status === LICENSE_STATUS.WARNING) {
      licenseMessage = "license almost expired";
    } else if (this.state.acrLicense.status === LICENSE_STATUS.ERROR) {
      licenseMessage = "license invalid";
    }

    licenseMessage = translate(licenseMessage);
    if (this.state.acrLicense.expirationDate) {
      licenseValidity = `${translate("expiration date")}: ${format(
        new Date(this.state.acrLicense.expirationDate),
        "yyyy/MM/dd"
      )}`;
    }

    return (
      <Box display="flex" flexDirection="column">
        <Typography variant="h6">
          <p data-cy=" license-status-label">{licenseMessage}.</p>
          {licenseValidity ? <p data-cy="expiration-date-label">{licenseValidity}.</p> : <br />}
        </Typography>
      </Box>
    );
  }
}
