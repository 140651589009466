import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography, Box } from '@material-ui/core';
import { Context } from 'vm';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

export function NetworkError(ctx: Context) {
  const { t } = useTranslation();
  return (
    <Box
      display='flex'
      flexDirection='column'
      style={{ width: '100%' }}
      alignItems='center'
      justifyContent='center'
      m={5}>
      <Box display='flex' alignItems='center'>
        <ErrorOutlineIcon
          fontSize={'large'}
          style={{ fontSize: 80, marginRight: '0.25em' }}
          color={'error'}
        />
        <Typography variant='h4' color='error'>
          {t('network error')}
        </Typography>
      </Box>
      <Typography variant='h6' color='error'>
        {t('check server connection')}
      </Typography>
    </Box>
  );
}
