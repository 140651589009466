import React from "react";
import AppBar from "@material-ui/core/AppBar";
import { Box, Typography, Link } from "@material-ui/core";
import PersonIcon from "@material-ui/icons/Person";
import { Subscription } from "rxjs";

import { loginService } from "../../utils/login";
import { Channel } from "../../models/ChannelModel";
import { ServerLed } from "./ServerLed";
import { AcrPopOver } from "../AcrPopOver";
import { ACRMenu } from "./AcrMenu";
import { ChannelsState } from "./ChannelsState";
import { acrAPI } from "../../utils/api";
import { translate } from "../../i18n";

export class AcrAppBar extends React.Component {
  private subscribtions: Subscription = new Subscription();

  public state = {
    channelOkCount: 0,
    channelWarningCount: 0,
    channelErrorCount: 0,
    channelStopCount: 0,
    logged: false,
  };

  constructor(props: any) {
    super(props);
    this.updateChannels = this.updateChannels.bind(this);
    this.updateLoginState = this.updateLoginState.bind(this);
  }

  public componentDidMount(): void {
    this.subscribtions.add(acrAPI.channelsSubject.subscribe(this.updateChannels));
    this.subscribtions.add(loginService.isLoggedSubject.subscribe(this.updateLoginState));
  }

  public componentWillUnmount(): void {
    this.subscribtions.unsubscribe();
  }

  private updateLoginState(logged: boolean): void {
    this.setState({ logged });
  }

  private updateChannels(channels: Array<Channel>): void {
    let okCount = 0;
    let warningCount = 0;
    let errorCount = 0;
    let stopCount = 0;
    channels.forEach((channel) => {
      if (channel.isOk()) {
        okCount += 1;
      } else if (channel.isWarning()) {
        warningCount += 1;
      } else if (channel.isError()) {
        errorCount += 1;
      } else if (channel.isStop()) {
        stopCount += 1;
    }
    });
    this.setState({ channelOkCount: okCount, channelWarningCount: warningCount, channelErrorCount: errorCount, channelStopCount: stopCount });
    //console.log(`[${this.name}] updateChannels ok:${okCount} warning:${warningCount} error:${errorCount} DONE`); // TODO remove
  }

  public render(): JSX.Element {
    return (
      <AppBar color="inherit" position="relative">
        <Box display="flex" flexWrap="wrap">
          <Box display="flex" flexDirection="column" width="375px" alignSelf="center">
            {this.state.logged === true && <ACRMenu />}
          </Box>
          <Box display="flex" flexGrow={1} justifyContent="center" alignItems="center" alignContent="center" m={2}>
            <Link href="/mosaic" underline="none">
              <Typography variant="h3" color="textSecondary" style={{ textTransform: "none" }}>
                Watermarking Monitor
              </Typography>
            </Link>
          </Box>
          {this.state.logged === false && <Box display="flex" style={{ width: "90px" }}></Box>}

          <Box
            display="flex"
            flexDirection="column"
            alignItems="flex-end"
            alignSelf="center"
            style={{ margin: "0 0 0 auto" }}
            paddingRight={2}>
            <Box display="flex">
              <Box display="flex" flexDirection="row" alignItems="center">
                <ChannelsState
                  channelOkCount={this.state.channelOkCount}
                  channelWarningCount={this.state.channelWarningCount}
                  channelErrorCount={this.state.channelErrorCount}
                  channelStopCount={this.state.channelStopCount}
                />
                <Typography data-cy="server-status-label" style={{ width: "65px" }} color="textSecondary">
                  {translate("Server status")}
                </Typography>
                <ServerLed />
              </Box>
              {this.state.logged === true && (
                <Box display="flex" alignItems="center" paddingLeft={1}>
                  <AcrPopOver
                    buttonDataCy="acr-app-bar-admin-button"
                    labelDataCy="acr-app-bar-admin-label"
                    icon={<PersonIcon />}
                    anchor={localStorage.getItem("login")}
                    content={
                      <Link href="/logout" data-cy="acr-app-bar-logout-button">
                        <Typography
                          style={{ margin: "1em" }}
                          variant="body1"
                          color="textSecondary"
                          data-cy="acr-app-bar-logout-label">
                          {translate("logout")}
                        </Typography>
                      </Link>
                    }
                  />
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      </AppBar>
    );
  }
}
