//TODO create a npm package server and add this to a utils package in order to share this kind of thing between client and server
export function getFormatedUtcDate(inputDate: Date | null) {
  if (!inputDate) {
    return "undefined";
  }
  const convertedDate = new Date(inputDate);
  //date
  const year = convertedDate.getUTCFullYear();
  if (year < 2006) {
    return "N/A";
  }
  const rawMonth = convertedDate.getUTCMonth() + 1;
  const month = rawMonth > 9 ? rawMonth : `0${rawMonth}`;
  const rawDate = convertedDate.getUTCDate();
  const date = rawDate > 9 ? rawDate : `0${rawDate}`;
  //time
  const rawHours = convertedDate.getUTCHours();
  const hours = rawHours > 9 ? rawHours : `0${rawHours}`;
  const rawMinutes = convertedDate.getUTCMinutes();
  const minutes = rawMinutes > 9 ? rawMinutes : `0${rawMinutes}`;
  const rawSeconds = convertedDate.getUTCSeconds();
  const seconds = rawSeconds > 9 ? rawSeconds : `0${rawSeconds}`;
  return `${year}/${month}/${date}  - ${hours}:${minutes}:${seconds}`;
}
