import React from "react";
import Box from "@material-ui/core/Box";

import { Subscription } from "rxjs";

import { Channel } from "../models/ChannelModel";
import { ChannelSquare } from "../components/Mosaic/ChannelSquare";
import { acrAPI } from "../utils/api";
import { LICENSE_STATUS, License } from "../models/LicenseModel";
import { CreateChannelButton } from "../components/Mosaic/CreateChannelButton";

export class Mosaic extends React.Component {
  public state = { license: new License(), channels: new Array<Channel>() };

  private subscribtions: Subscription = new Subscription();

  constructor(props: any) {
    super(props);
    this.updateLicense = this.updateLicense.bind(this);
    this.updateChannels = this.updateChannels.bind(this);
  }
  public componentDidMount(): void {
    this.subscribtions.add(acrAPI.licenseSubject.subscribe(this.updateLicense));
    this.subscribtions.add(acrAPI.channelsSubject.subscribe(this.updateChannels));
  }

  public componentWillUnmount(): void {
    this.subscribtions.unsubscribe();
  }
  public shouldComponentUpdate(): boolean {
    return acrAPI.channelsSubject.getValue() !== this.state.channels;
  }
  private updateLicense(value: License): void {
    this.setState({ license: value });
  }

  private updateChannels(value: Array<Channel>): void {
    this.setState({ channels: value });
  }

  public render(): JSX.Element {
    return (
      <Box style={{ overflowY: "auto", overflowX: "hidden" }}>
        <Box margin="1em 0 1em 4em ">
          <Box display="flex" alignContent="flex-start" flexWrap="wrap" alignItems="center" style={{ overflow: "hidden" }}>
            <CreateChannelButton disabled={acrAPI.channelsSubject.getValue().length >= 500} />
            {this.state.channels.map((channel: Channel, index) => (
              <ChannelSquare
                mosaicIndex={index}
                key={channel.id}
                channel={channel}
                validLicense={!(this.state.license?.status === LICENSE_STATUS.ERROR)}
                qualityIndexEnable={this.state.license.qualityIndexEnable}
              />
            ))}
          </Box>
        </Box>
      </Box>
    );
  }
}
