import React from "react";
import { Redirect } from "react-router";

import { loginService } from "../utils/login";

export const Logout = () => {
  loginService.logout();
  return (
    <Redirect
      to={{
        pathname: "/login",
        state: { referrer: "/mosaic" },
      }}
    />
  );
};
