import React from "react";
import { Subscription } from "rxjs";
import { Box, AppBar, Tabs, Tab } from "@material-ui/core";

import { SoftwareLogs } from "./SoftwareLogs";
import { ChannelInformation } from "../components/ChannelInformation";
import { SimpleTabPanel } from "../components/Header/SimpleTabPanel";
import { translate } from "../i18n";
import { acrAPI } from "../utils/api";
import { License } from "../models/LicenseModel";
import { LogTableContainer } from "components/Logs/Table/LogTableContainer";
import { QualityIndexLog } from "acr-common-models";
import { WatermarkLog } from "models/WatermarkLogModel";
import { Channel } from "models/ChannelModel";

export class ChannelDetail extends React.Component {
  private subscribtions: Subscription = new Subscription();
  public state = { tab: 0, license: new License(), channel: new Channel() };
  //history manangement, we push this page in order to go back to the mosaic page
  //Note: we can acess to this current page only if we are on the mosaic and if we click on the + button

  private channelId = -1;
  //If we do not pass an id throught the context, it means that we are currently creating a new channel
  //In this case the two other tabs will not be activated and the attached component will not be created
  //This will avoid to fetch software and watermark logs on a channel not created yet.
  private isNewChannel = true;
  constructor(props: any) {
    super(props);
    this.updateLicense = this.updateLicense.bind(this);
    this.updateChannel = this.updateChannel.bind(this);

    this.isNewChannel = isNaN(props.id);
    this.channelId = Number(props.id);

    //history manangement, we push this page in order to go back to the mosaic page
    //Note: we can acess to this current page only if we are on the mosaic and if we click on the + button
    window.onpopstate = () => {
      window.location.href = "/mosaic";
    };
  }

  public componentDidMount(): void {
    this.subscribtions.add(acrAPI.licenseSubject.subscribe(this.updateLicense));
    this.subscribtions.add(acrAPI.channelsSubject.subscribe(this.updateChannel));
  }

  public componentWillUnmount(): void {
    this.subscribtions.unsubscribe();
  }
  public updateChannel(channels: Channel[]): void {
    const channel = channels.find((channel) => channel.id === this.channelId);
    //console.log(channel);
    this.setState({ channel });
  }
  private updateLicense(license: License): void {
    this.setState({ license });
  }

  //history manangement, we push this page in order to go back to the mosaic page
  //Note: we can acess to this current page only if we are on the mosaic and if we click on the + button
  private a11yProps(index: any): object {
    return {
      "id": `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  private tabChange = (event: React.ChangeEvent<{}>, tabId: number) => {
    this.setState({ tab: tabId });
  };

  public render(): JSX.Element {
    return (
      <Box padding={"2em"} width="100%">
        <Box>
          <AppBar position="static">
            <Tabs value={this.state.tab} onChange={this.tabChange}>
              <Tab data-cy="channel-detail-information" label={translate("channel information")} {...this.a11yProps(0)} />
              <Tab
                data-cy="channel-detail-channel-logs-tab"
                disabled={this.isNewChannel}
                label={translate("channel logs")}
                {...this.a11yProps(1)}
              />
              <Tab
                data-cy="channel-detail-detection-logs-tab"
                disabled={this.isNewChannel}
                label={translate("channel detection")}
                {...this.a11yProps(2)}
              />
              { this.state.license.qualityIndexEnable &&
                this.state.channel?.channelType !== "am" &&
                ( // TODO if qualityIndexEnableFromUI is false, do not display quality index Tab? 
                <Tab
                  data-cy="channel-detail-quality-index"
                  disabled={this.isNewChannel}
                  label={translate("quality index")}
                  {...this.a11yProps(3)}
                />
              )}
            </Tabs>
          </AppBar>
        </Box>
        <SimpleTabPanel value={this.state.tab} index={0}>
          <ChannelInformation channelId={this.channelId} channelTypeInfo={this.state.channel?.channelType} qualityIndexEnableFromLicense={this.state.license.qualityIndexEnable}/>
        </SimpleTabPanel>
        <SimpleTabPanel value={this.state.tab} index={1}>
          {!this.isNewChannel && <SoftwareLogs channelId={this.channelId} />}
        </SimpleTabPanel>
        <SimpleTabPanel value={this.state.tab} index={2}>
          {!this.isNewChannel && <LogTableContainer apiPrefix="watermarkLog" logType={WatermarkLog} channelId={this.channelId} />}
        </SimpleTabPanel>
        { this.state.license.qualityIndexEnable && 
          this.state.channel?.channelType !== "am" && 
          ( // TODO if qualityIndexEnableFromUI is false, do not display quality index Tab?
          <>
            <SimpleTabPanel value={this.state.tab} index={3}>
              {!this.isNewChannel && (
                <LogTableContainer apiPrefix="qualityIndexLog" logType={QualityIndexLog} channelId={this.channelId} />
              )}
            </SimpleTabPanel>
          </>
        )}
      </Box>
    );
  }
}
