import React from "react";

import { Box, Typography, TextField, Button } from "@material-ui/core";
import { translate } from "i18n";
import { WatermarkingConfigurationModel } from "acr-common-models";
import { acrAPI } from "utils/api";

import { Subscription } from "rxjs";
import { object, number } from "yup";
import { Formik, Form, Field, FormikTouched } from "formik";

export class WatermarkingConfiguration extends React.Component {
  private subscribtions: Subscription = new Subscription();
  private validationSchema = object({
    minTimeCodeDrift: number()
      .required("A minimum time code drift value is needed")
      .min(-3600, "Value should be between -3600 and -2")
      .max(-2, "Value should be between -3600 and -2"),
    maxTimeCodeDrift: number()
      .required("A maximum time code drift value is needed")
      .min(2, "Value should be between 2 and 3600")
      .max(3600, "Value should be between 2 and 3600"),
    nbEvent: number()
      .required("Number of event occurence value is needed")
      .min(1, "Value should be between 1 and 40")
      .max(40, "Value should be between 1 and 40"),
    nbRestartAlarm: number()
      .required("Number of restart alarm value is needed")
      .min(1, "Value should be between 1 and 20")
      .max(20, "Value should be between 1 and 20"),
  });
  public state = { minTimeCodeDrift: -60, maxTimeCodeDrift: 60, nbEvent: 10, nbRestartAlarm: 1 };

  constructor(props: any) {
    super(props);
    this.updateConfiguration = this.updateConfiguration.bind(this);
  }
  public componentDidMount(): void {
    this.setState({ minTimeCodeDrift: -60, maxTimeCodeDrift: 60, nbEvent: 10, nbRestartAlarm: 1 });
    this.subscribtions.add(acrAPI.watermarkingConfigurationSubject.subscribe(this.updateConfiguration));
    acrAPI.getWatermarkingConfiguration();
  }

  public componentWillUnmount(): void {
    this.subscribtions.unsubscribe();
  }
  private canSubmit(errors: any, touched: FormikTouched<WatermarkingConfigurationModel>): boolean {
    return Object.keys(errors).length === 0 && Object.keys(touched).length > 0;
  }

  private async handleSubmit(values: any): Promise<void> {
    await acrAPI.updateWatermarkingConfiguration(values);
  }

  private updateConfiguration(watermarkingConfigurationModel: WatermarkingConfigurationModel): void {
    console.log(watermarkingConfigurationModel, this.state);
    this.setState({ ...watermarkingConfigurationModel });
  }
  public render(): JSX.Element {
    return (
      <Box marginLeft={2} p={2} style={{ width: "50em" }}>
        <Typography data-cy="settings-watermaking-configuration-label" variant="h4">
          {translate("Watermarking configuration")}
        </Typography>
        <Formik
          initialValues={this.state}
          enableReinitialize={true}
          validationSchema={this.validationSchema}
          onSubmit={async (values, { resetForm }) => {
            await this.handleSubmit(values);
            resetForm({ values });
          }}>
          {({ handleSubmit, handleChange, setFieldTouched, touched, values, errors }) => (
            <Form name="settings-snmp-configuration-form" translate="none" method="POST" onSubmit={handleSubmit}>
              <Box display="flex" flexDirection={"column"} justifyContent="space-between" width={"38em"}>
                <Field
                  component={TextField}
                  variant="outlined"
                  margin="normal"
                  id="minTimeCodeDrift"
                  name="minTimeCodeDrift"
                  label={translate("Minimum time code drift")}
                  fullWidth
                  helperText={errors.minTimeCodeDrift}
                  onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                    setFieldTouched("minTimeCodeDrift", true);
                    handleChange(e);
                  }}
                  values={values.minTimeCodeDrift}
                  value={values.minTimeCodeDrift}
                  data-cy={`snmp-community-name`}
                />
                <Field
                  component={TextField}
                  variant="outlined"
                  margin="normal"
                  id="maxTimeCodeDrift"
                  name="maxTimeCodeDrift"
                  fullWidth
                  label={translate("Maximum time code drift")}
                  helperText={errors.maxTimeCodeDrift}
                  onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                    setFieldTouched("maxTimeCodeDrift", true);
                    handleChange(e);
                  }}
                  values={values.maxTimeCodeDrift}
                  value={values.maxTimeCodeDrift}
                  data-cy={`snmp-community-name`}
                />
                <Field
                  component={TextField}
                  variant="outlined"
                  margin="normal"
                  id="nbEvent"
                  fullWidth
                  name="nbEvent"
                  label={translate("Number of event")}
                  helperText={errors.nbEvent}
                  onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                    setFieldTouched("nbEvent", true);
                    handleChange(e);
                  }}
                  values={values.nbEvent}
                  value={values.nbEvent}
                  data-cy={`snmp-community-name`}
                />
                <Field
                  component={TextField}
                  variant="outlined"
                  margin="normal"
                  id="nbRestartAlarm"
                  fullWidth
                  name="nbRestartAlarm"
                  label={translate("Number of restart alarm")}
                  helperText={errors.nbRestartAlarm}
                  onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                    setFieldTouched("nbRestartAlarm", true);
                    handleChange(e);
                  }}
                  values={values.nbRestartAlarm}
                  value={values.nbRestartAlarm}
                  data-cy={`snmp-community-name`}
                />
                <Button
                  type="submit"
                  disabled={!this.canSubmit(errors, touched)}
                  variant="contained"
                  data-cy="snmp-settings-configuration-save-button"
                  style={{ margin: "1.25em 0 1.5em 0em", height: "2.5em", padding: "0 2em" }}
                  color="primary">
                  {translate("save")}
                </Button>
              </Box>
            </Form>
          )}
        </Formik>
      </Box>
    );
  }
}
