import React from "react";
import { Typography, Box } from "@material-ui/core";

interface ISimpleTabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

export class SimpleTabPanel extends React.Component<ISimpleTabPanelProps> {
  public render(): JSX.Element {
    return (
      <Typography
        component={"div" as any}
        role="tabpanel"
        hidden={this.props.value !== this.props.index}
        id={`simple-tabpanel-${this.props.index}`}
        aria-labelledby={`simple-tab-${this.props.index}`}>
        <Box>{this.props.children}</Box>
      </Typography>
    );
  }
}
