import React from "react";
import { Led } from "./Led";

import { ILedColors } from "../../utils/colors";

interface IChannelLedProps {
  nbActiveItems: number;
  colors: ILedColors;
  dataCy: string;
}

export class ChannelLed extends React.Component<IChannelLedProps> {
  public render(): JSX.Element {
    return <Led dataCy={this.props.dataCy} colors={this.props.colors} label={this.props.nbActiveItems} />;
  }
}
