import React, { ChangeEvent, MouseEvent, KeyboardEvent } from "react";
import { Box, Button, Typography, TextField, InputLabel, withStyles } from "@material-ui/core";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { translate } from "../../../i18n";

const useStyles = () => ({
  container: {
    padding: "15px 8px 8px 0px",
  },
  totalPageLabel: {
    margin: "0 1rem",
    fontSize: "16px",
  },
  textField: {
    width: "60px",
    paddingLeft: "1em",
  },
  button: { height: "2.6em", marginTop: "5px" },
});

interface IPaginationProps {
  pageNumber: number | "";
  totalPageCount: number;
  handlePreviousPage: (event: MouseEvent<HTMLButtonElement>) => void;
  handleNextPage: (event: MouseEvent<HTMLButtonElement>) => void;
  handlePageNumberChange: (event: ChangeEvent<{ name?: string; value: unknown }>) => void;
  onPaginationKeyUp: (event: KeyboardEvent<HTMLDivElement>) => void;
}
class Pagination extends React.Component<IPaginationProps> {
  public render(): JSX.Element {
    const tmpProps = this.props as any;
    const { classes } = tmpProps;
    return (
      <Box alignItems="center" display="flex" flexDirection="column" className={classes.container}>
        <InputLabel style={{ fontSize: "11px" }} data-cy="pagination-label">
          {translate("Pagination")}
        </InputLabel>
        <Box display="flex" alignItems="center">
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            disabled={!this.props.pageNumber || this.props.pageNumber <= 1}
            onClick={(e) => this.props.handlePreviousPage(e)}
            data-cy="pagination-previous-button">
            <ArrowBackIosIcon />
          </Button>
          <TextField
            className={classes.textField}
            type="tel"
            onKeyUp={(e) => this.props.onPaginationKeyUp(e)}
            onChange={(e) => this.props.handlePageNumberChange(e)}
            value={this.props.pageNumber}
            data-cy="pagination-page-number-input"
          />
          <Typography className={classes.totalPageLabel} data-cy="pagination-total-page-label">
            / {this.props.totalPageCount}
          </Typography>
          <Button
            disabled={this.props.pageNumber === this.props.totalPageCount}
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={(e) => this.props.handleNextPage(e)}
            data-cy="pagination-next-button">
            <ArrowForwardIosIcon />
          </Button>
        </Box>
      </Box>
    );
  }
}
export default withStyles(useStyles, { withTheme: true })(Pagination);
