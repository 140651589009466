import React from "react";
import { Box } from "@material-ui/core";

export class KantarLogo extends React.Component {
  public render(): JSX.Element {
    return (
      <Box
        display="flex"
        alignItems="center"
        //TODO STYLE
        style={{
          width: "100%",
          margin: "auto 0.2em",
          verticalAlign: "middle"
        }}>
        <img data-cy="kantar-logo-image" alt="Kantar Logo" height="18rem" src="/images/Kantar-Logo.png"></img>
      </Box>
    );
  }
}
