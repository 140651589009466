import { getFormatedUtcDate } from "./date";
import { translate } from "i18n";

export function capitalize(str: string): string {
  return str.charAt(0).toUpperCase() + str.slice(1);
}
export function formatResampling(pace: number): string {
  return pace.toString();
}
export function convertAndCapitalize(arg: boolean): string {
  const converted = arg.toString();
  return converted.charAt(0).toUpperCase() + converted.slice(1);
}
export function getWatermarkTimeStamp(timestamp: Date): string {
  const timestampAsDate = new Date(timestamp);
  if (timestampAsDate.getUTCFullYear() > 1970) {
    return getFormatedUtcDate(timestamp);
  }
  return "N/A";
}
export function formatWatermarkId(wmk: number): string {
  return wmk > 0 ? wmk.toString() : "N/A";
}

export function getPayloadTypeTxt(payloadType: string): string {
  if (payloadType === "identified") {
    return translate("detected watermark type");
  }
  if (payloadType === "not_detected") {
    return translate("not detected watermark type");
  }
  if (payloadType === "unidentified") {
    return translate("detected unidentified watermark type");
  }
  return "N/A";
}
