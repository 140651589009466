import React from "react";
import { Box, TableCell, Table, TableHead, TableRow, TableBody } from "@material-ui/core";

import { QualityIndexLog } from "acr-common-models";
import { WatermarkLog } from "models/WatermarkLogModel";

import { translate } from "../../../i18n";

export interface ILogColumn {
  cellName: keyof QualityIndexLog | keyof WatermarkLog;
  formatFunction: (args0: any) => string;
}

interface ILogTableProps {
  logs: Array<QualityIndexLog | WatermarkLog>;
  containerHeight: number;
  columns: Array<ILogColumn>;
}

export class LogTable extends React.Component<ILogTableProps> {
  private tableCell(property: keyof QualityIndexLog | keyof WatermarkLog, index: number): React.ReactElement {
    return (
      <TableCell key={index} component={"th" as any}>
        {translate(property)}
      </TableCell>
    );
  }
  private getCellValue(log: QualityIndexLog | WatermarkLog, cellName: string): any {
    //In typescript I did not find a way to access data dynamically as in javacript object[propertyName]
    //There is maybe a nicer way to do that.
    const keyValue = Object.entries(log).find((cell) => cell[0] === cellName);
    //Following condition is needed by typescript
    return keyValue && keyValue.length > 0 ? keyValue[1] : "";
  }

  public render(): JSX.Element {
    return (
      <Box style={{ maxHeight: this.props.containerHeight, overflow: "auto" }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow style={{ zIndex: 1 }}>
              {this.props.columns.map((column, columnIndex) => this.tableCell(column.cellName, columnIndex))}
            </TableRow>
          </TableHead>
          <TableBody style={{ overflow: "auto", width: "100%", height: "10%" }}>
            {this.props.logs.map((log: QualityIndexLog | WatermarkLog, rowIndex) => {
              return (
                <TableRow key={rowIndex}>
                  {this.props.columns.map((column, columnIndex) => (
                    <TableCell key={columnIndex} style={{ zIndex: 1 }}>
                      {column.formatFunction(this.getCellValue(log, column.cellName))}
                    </TableCell>
                  ))}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Box>
    );
  }
}
