import { AlarmsModel } from "acr-common-models";

export enum CHANNEL_STATUS {
  OK = "ok",
  WARNING = "warning",
  ERROR = "error",
  PENDING = "pending",
  STOP = "stop",
  STUCK = "stuck",
}

export enum QI_STATUS {
  GOOD = "good",
  MEDIUM = "medium",
  BAD = "bad",
}

export class ChannelTypes {
  public values = [
    { id: "am", name: "AM" },
    { id: "dab", name: "DAB" },
    { id: "dtt", name: "DTT" },
    { id: "fm", name: "FM" },
    { id: "sat", name: "SAT" },
    { id: "tv", name: "TV" },
    { id: "web", name: "WEB" },
  ];

  public name(id: string): string {
    let x = this.values.find((x) => x.id === id);
    return x ? x.name : id;
  }
}

export const channelTypes = new ChannelTypes();

// Same structure as server side, except we do not care about already logged boolean

type ChannelData = {
  id: number;
  name: string;
  channelEnable: boolean;
  channelType: string;
  status: CHANNEL_STATUS;
  validLicense: boolean;
  lastWatermarkId: number;
  lastTimestamp: string;
  url: string;
  alarms: AlarmsModel;
  watermarkIds: number[];
  qualityIndex: QI_STATUS;
  qualityIndexEnableFromUI: boolean;
  programNumber: string;
  pid: string;
};

export class Channel {
  public id: number = 0;
  public name: string = "";
  public channelEnable: boolean = true;
  public channelType: string = "";
  public status: CHANNEL_STATUS = CHANNEL_STATUS.PENDING;
  public validLicense: boolean = true;
  public lastWatermarkId = 0;
  public lastTimestamp = "";
  public url: string = "";
  public alarms: AlarmsModel;
  public watermarkIds: number[] = [];
  public qualityIndex: QI_STATUS = QI_STATUS.GOOD;
  public qualityIndexEnableFromUI: boolean = true;
  public programNumber: string = "";
  public pid: string = "";
  
  constructor(data?: ChannelData) {
    this.alarms = new AlarmsModel();
    if (data) {
      this.id = data.id;
      this.name = data.name;
      this.channelEnable = data.channelEnable;
      this.channelType = data.channelType;
      this.status = data.status;
      this.validLicense = data.validLicense;
      this.lastWatermarkId = data.lastWatermarkId;
      this.lastTimestamp = data.lastTimestamp;
      this.url = data.url;
      this.alarms = data.alarms;
      this.watermarkIds = data.watermarkIds;
      this.qualityIndex = QI_STATUS.GOOD;
      this.qualityIndexEnableFromUI = data.qualityIndexEnableFromUI;
      this.programNumber = data.programNumber;
      this.pid = data.pid;
    }
  }

  public isOk = () => this.status === CHANNEL_STATUS.OK;
  public isError = () => this.status === CHANNEL_STATUS.ERROR;
  public isWarning = () => this.status === CHANNEL_STATUS.WARNING;
  public isPending = () => this.status === CHANNEL_STATUS.PENDING;
  public isStop = () => this.status === CHANNEL_STATUS.STOP;
}
