import axios from "axios";
import * as HttpStatus from "http-status-codes";
import i18n from "../i18n";
import { parseISO } from "date-fns";
import { BehaviorSubject } from "rxjs";
import { License } from "../models/LicenseModel";
import { Channel } from "../models/ChannelModel";
import { QualityIndexLog, SnmpConfigurationModel, TrapReceiverModel, SmtpConfigModel, EmailReceiverModel, WatermarkingConfigurationModel } from "acr-common-models";
import { WatermarkLog } from "models/WatermarkLogModel";
import { loginService } from "./login";
require("dotenv").config();

export const ERROR_NB_MAX_EMAIL = 20;
interface ILogResponse {
  logs: Array<QualityIndexLog | WatermarkLog>;
  totalLogCount: number;
}


class API {
  private static instance: API;
  private lastPageBeforNetworkError = "";
  private NETWORK_ERROR_PAGE = "/networkError";
  public serverDateSubject = new BehaviorSubject<Date>(new Date());
  public connexionStatusSubject = new BehaviorSubject<boolean | null>(null);
  public licenseSubject = new BehaviorSubject<License>(new License());
  public channelsSubject = new BehaviorSubject<Array<Channel>>(new Array<Channel>());
  public versionSubject = new BehaviorSubject<string>("...");
  public trapReceiversSubject = new BehaviorSubject<TrapReceiverModel[]>(new Array<TrapReceiverModel>());
  public communityNameSubject = new BehaviorSubject<string>("Loading...");
  public emailReceiversSubject = new BehaviorSubject<EmailReceiverModel[]>(new Array<EmailReceiverModel>());
  public smtpConfigSubject = new BehaviorSubject<SmtpConfigModel>(new SmtpConfigModel());
  public watermarkingConfigurationSubject = new BehaviorSubject<WatermarkingConfigurationModel>(
    new WatermarkingConfigurationModel()
  );

  private constructor() {}

  public init(): void {
    axios.defaults.baseURL = this.getBaseURL();
    axios.defaults.headers.get["Access-Control-Allow-Origin"] = "*";

    axios.interceptors.request.use(
      (request) => {
        if (window.location.href.indexOf("/settings/update") < 0) {
          const userLanguage = localStorage.getItem("language");
          if (userLanguage) {
            i18n.changeLanguage(userLanguage);
          }
        }
        const token = localStorage.getItem("access_token");
        if (token) {
          if(request && request.headers)
            request.headers["Authorization"] = "Bearer " + token;
        }
        return request;
      },
      (error) => {
        console.error("Failed to add login header!", error);
      }
    );

    axios.interceptors.response.use(
      (response) => {
        if (window.location.href.indexOf(this.NETWORK_ERROR_PAGE) > 0) {
          if (this.lastPageBeforNetworkError.length > 0 && this.lastPageBeforNetworkError.indexOf(this.NETWORK_ERROR_PAGE) < 0) {
            window.location.href = this.lastPageBeforNetworkError;
          } else {
            window.location.href = "/mosaic";
          }
        }
        this.lastPageBeforNetworkError = window.location.href;
        this.connexionStatusSubject.next(true);
        return response;
      },
      (error) => {
        console.error(error);
        if (
          error.response &&
          (error.response.status === HttpStatus.BAD_REQUEST ||
            error.response.status === HttpStatus.NOT_FOUND ||
            error.response.status === HttpStatus.CONFLICT)
        ) {
          return Promise.reject(error.response);
        }
        if (error.response && error.response.status === HttpStatus.UNAUTHORIZED) {
          loginService.logout();
          window.location.href = '/login';
          return Promise.reject(error.response);
        }
        if (window.location.href.indexOf(this.NETWORK_ERROR_PAGE) < 0) {
          this.lastPageBeforNetworkError = window.location.href;
          window.location.href = this.NETWORK_ERROR_PAGE;
        }
        this.connexionStatusSubject.next(false);

        // Do something with response error
        if (!error.response) {
          console.error(`Server does not seems to respond:`, error);
          return Promise.reject(error.response);
        }
        return Promise.reject(error.response);
      }
    );
  }

  public static getInstance(): API {
    return this.instance || (this.instance = new this());
  }

  /////////////////// Observables
  /*public getServerClockObservab(): Observable<Date | null> {
    return this.serverDateSubject.asObservable();
  }*/

  /////////////////// API
  public async getStatus(): Promise<number | void> {
    return await axios
      .get("/status")
      .then((response) => {
        let serverDate = new Date();
        if (response.data && response.data.date) {
          serverDate = parseISO(response.data.date);
          this.serverDateSubject.next(serverDate);
        }
        //acrFooterContext.serverDate = serverDate;
        const dataChannels: Channel[] = response.data.channels?.sort((a: Channel, b: Channel) => a.name.localeCompare(b.name));

        // Set alphabetically order for channels:
        let channels = new Array<Channel>();
        dataChannels?.forEach((dataChannel) => {
          const channel = new Channel({ ...dataChannel });
          channels.push(channel);
        });
        this.channelsSubject.next(channels);
        return response.status;
      })
      .catch((e) => {
        console.log(e);
      });
  }

  public async getVersion(): Promise<string> {
    let res = "...";
    await axios.get("/version").then((versionReq) => {
      res = versionReq.data || "...";
      this.versionSubject.next(res);
    });
    return res;
  }

  public async getLicense(): Promise<License> {
    let license = new License();
    await axios.get("/license").then((response) => {
      if (response && response.data.licenses.length) {
        const licData = response.data.licenses[0];
        license.status = licData.status;
        license.qualityIndexEnable = licData.qualityIndexEnable;
        if (licData.expirationDate) {
          license.expirationDate = licData.expirationDate;
        }
        this.licenseSubject.next(license);
      }
    });
    return license;
  }

  public async getSnmpConfiguration(): Promise<void> {
    await axios
      .get("/snmp")
      .then((response) => {
        const { trapReceivers, communityName } = response.data;
        this.trapReceiversSubject.next(trapReceivers);
        this.communityNameSubject.next(communityName);
      })
      .catch((e) => console.error("Failed to get snmp configuration", e));
  }

  public async updateSnmpConfiguration(values: any): Promise<SnmpConfigurationModel> {
    return await axios
      .patch(`/snmp/configuration`, values)
      .then((response) => response.data)
      .catch((e) => console.error("Failed to update snmp configuration: ", e));
  }

  public async addTrapReceiver(trapReceiver: TrapReceiverModel): Promise<boolean> {
    let isConflicting = false;

    await axios
      .post(`/snmp/trapReceiver`, trapReceiver)
      .then((response) => {
        const updatedTrapReceivers = response.data;
        this.trapReceiversSubject.next(updatedTrapReceivers);
      })
      .catch((e) => {
        console.error("Failed to add trap receiver: ", e);
        isConflicting = e.status === HttpStatus.CONFLICT;
      });

    return isConflicting;
  }

  public async updateTrapReceiver(id: number, trapReceiver: TrapReceiverModel): Promise<boolean> {
    let isConflicting = false;
    await axios
      .patch(`/snmp/trapReceiver/${id}`, trapReceiver)
      .then((response) => {
        const updatedTrapReceivers = response.data;
        if (updatedTrapReceivers) {
          this.trapReceiversSubject.next(updatedTrapReceivers);
        }
      })
      .catch((e) => {
        console.error("Failed to update trap receiver: ", e);
        isConflicting = e.status === HttpStatus.CONFLICT;
      });
    return isConflicting;
  }

  public async deleteTrapReceiver(id: number): Promise<void> {
    await axios
      .delete(`/snmp/trapReceiver/${id}`)
      .then(async (_) => {
        const response = await axios.get("/snmp");
        const updatedTrapReceivers = response.data.trapReceivers;
        this.trapReceiversSubject.next(updatedTrapReceivers);
      })
      .catch((e) => console.error("Failed to delete trap receiver: ", e));
  }

  public async getEmailConfiguration(): Promise<boolean> {
    let errorNbEmail = false;
    await axios
      .get("/email")
      .then((response) => {
        const { emailReceivers, smtpConfig } = response.data;
        if (emailReceivers.length >= ERROR_NB_MAX_EMAIL) {
          errorNbEmail = true;
        }
        this.emailReceiversSubject.next(emailReceivers);
        this.smtpConfigSubject.next(smtpConfig);
      })
      .catch((e) => console.error("Failed to get email SMTP configuration", e));
    
    return errorNbEmail;
  }
  public async updateSmtpConfiguration(smtpConfig: SmtpConfigModel): Promise<boolean> {
    await axios
      .patch(`/email/configuration`, smtpConfig )
      .then((response) => response.data)
      .catch((e) => {
        console.error("Failed to update email SMTP configuration: ", e);
      });
    return true;
  }

  public async addEmailReceiver(emailReceiver: EmailReceiverModel): Promise<boolean> {
    let isConflicting = false;
    // Do not add the new email receiver if the number of email is up to ERROR_NB_MAX_EMAIL
    if (await this.getEmailConfiguration())
      return false;

    await axios
      .post(`/email/emailReceiver`, emailReceiver)
      .then((response) => {
        const updatedEmailReceivers = response.data;
        this.emailReceiversSubject.next(updatedEmailReceivers);
      })
      .catch((e) => {
        console.error("Failed to add email receiver: ", e);
        isConflicting = e.status === HttpStatus.CONFLICT;
      });

    return isConflicting;
  }
  public async updateEmailReceiver(id: number, emailReceiver: EmailReceiverModel): Promise<boolean> {
    let isConflicting = false;
    await axios
      .patch(`/email/emailReceiver/${id}`, emailReceiver)
      .then((response) => {
        const updatedEmailReceivers = response.data;
        if (updatedEmailReceivers) {
          this.emailReceiversSubject.next(updatedEmailReceivers);
        }
      })
      .catch((e) => {
        console.error("Failed to update email receiver: ", e);
        isConflicting = e.status === HttpStatus.CONFLICT;
      });
    return isConflicting;
  }
  public async deleteEmailReceiver(id: number): Promise<void> {
    await axios
      .delete(`/email/emailReceiver/${id}`)
      .then(async (_) => {
        const response = await axios.get("/email");
        const updatedEmailReceivers = response.data.emailReceivers;
        this.emailReceiversSubject.next(updatedEmailReceivers);
      })
      .catch((e) => console.error("Failed to delete email receiver: ", e));
  }

  public async getWatermarkingConfiguration(): Promise<void> {
    await axios
      .get("/watermarkingConfiguration")
      .then((response) => {
        const watermarkingConfiguration = response.data;
        this.watermarkingConfigurationSubject.next(watermarkingConfiguration);
      })
      .catch((e) => console.error("Failed to get watermarking configuration", e));
  }
  public async updateWatermarkingConfiguration(watermarkingConfigurationModel: WatermarkingConfigurationModel): Promise<void> {
    await axios
      .patch("/watermarkingConfiguration/1", watermarkingConfigurationModel)
      .then((response) => {
        this.watermarkingConfigurationSubject.next(watermarkingConfigurationModel);
      })
      .catch((e) => console.error("Failed to update watermarking configuration", e));
  }

  public async getLogs(apiPrefix: string, channelId: number, logPerPageCount: number, pageNumber: number): Promise<ILogResponse> {
    let searchURL = `${apiPrefix}?channelId=${channelId}&count=${logPerPageCount}&pageNumber=${pageNumber}`;
    const rawResponse = await axios.get(searchURL).then((rawResponse) => {
      const response: ILogResponse = {
        logs: rawResponse?.data.logs || [],
        totalLogCount: rawResponse?.data.count || 0,
      };
      return response;
    });
    return rawResponse;
  }

  private getBaseURL(): string {
    const domain = process.env.REACT_APP_SERVER_DOMAIN;
    const port = Number(process.env.REACT_APP_SERVER_PORT);
    let baseUrl: String;
    if (domain && port) {
      baseUrl = `${domain}:${port}`;
    } else if (port) {
      baseUrl = `http://${window.location.hostname}:${port}`;
    } else {
      baseUrl = window.location.origin;
    }
    const url = baseUrl + "/api/1.1";

    return url;
  }
}

export const acrAPI = API.getInstance();
