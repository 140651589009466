import React from "react";
import { Typography } from "@material-ui/core";
import { translate } from "i18n";
import { ERROR_NB_MAX_EMAIL } from "../../../utils/api";

interface IEmailReceiverFormErrorsProps {
  errors?: any;
  conflict: boolean;
  errorNbEmail: boolean;
}

export class EmailReceiverFormErrors extends React.Component<IEmailReceiverFormErrorsProps> {
  public render(): JSX.Element {
    return (
      <>
        {this.props.errors?.emailAddress && (
          <Typography data-cy="snmp-settings-email-receiver-emailAddress-error" color="error">
            {translate(`bad email format`)}
          </Typography>
        )}
        {this.props.errorNbEmail && (
          <Typography data-cy="snmp-settings-number-email-receiver-emailAddress-error" color="error">
            {translate(`number of email is limited to `) + ERROR_NB_MAX_EMAIL}
          </Typography>
        )}
        {this.props.conflict && (
          <Typography data-cy="snmp-settings-email-receiver-conflict-error" color="error">
            {translate(`an email with same name already exists`)}
          </Typography>
        )}
      </>
    );
  }
}
