import React from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { Box, Typography } from "@material-ui/core";
import { Formik, Field, Form } from "formik";
import { object, string } from "yup";
import { loginService } from "../utils/login";
import { translate } from "i18n";

export class Login extends React.Component {
  private validationSchema = object({
    login: string().required("Login is required"),
    password: string().required("Password is required"),
  });
  public state = { badLoginCredential: false, badPasswordCredential: false };
  public async handleSubmit(data: any): Promise<void> {
    loginService
      .authenticate({
        login: data.login,
        password: data.password,
      })
      .catch((e) => {
        if (e.data === "BAD_PASSWORD") {
          this.setState({ badLoginCredential: false });
          this.setState({ badPasswordCredential: true });
        } else if (e.data === "BAD_LOGIN") {
          this.setState({ badLoginCredential: true });
          this.setState({ badPasswordCredential: false });
        }
      });
  }
  public render(): JSX.Element {
    return (
      <Box style={{ maxWidth: "30%", margin: "auto" }}>
        <Formik
          initialValues={{ login: "", password: "" }}
          enableReinitialize={false}
          onSubmit={(values) => this.handleSubmit(values)}
          validationSchema={this.validationSchema}>
          {({ handleSubmit, handleChange, values, errors }) => (
            <Form translate="none" method="POST" onSubmit={handleSubmit}>
              <Box display="flex" flexDirection="column">
                <Field
                  component={TextField}
                  variant="outlined"
                  margin="normal"
                  id="login"
                  label={translate("login")}
                  name="login"
                  helperText={errors.login}
                  onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => handleChange(e)}
                  value={values.login}
                  data-cy="login-user"
                />
                <Field
                  component={TextField}
                  variant="outlined"
                  margin="normal"
                  id="password"
                  name="password"
                  label={translate("password")}
                  helperText={errors.password}
                  onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => handleChange(e)}
                  type="password"
                  value={values.password}
                  data-cy="login-password"
                />
                {this.state.badLoginCredential && <Typography color="error">{translate("bad login")}</Typography>}
                {this.state.badPasswordCredential && <Typography color="error">{translate("bad password")}</Typography>}
                <Button type="submit" id="formSubmit" variant="contained" data-cy="login-signin-button" color="primary">
                  {translate("sign in")}
                </Button>
              </Box>
            </Form>
          )}
        </Formik>
      </Box>
    );
  }
}
