import React from "react";
import { Subscription } from "rxjs";
import { Box, Typography } from "@material-ui/core";
import { translate } from "i18n";
import { AddNewEmailReceiver } from "./AddNewEmailReceiver";
import { EmailReceiverModel } from "acr-common-models";
import { EmailReceiver } from "./EmailReceiver";
import { acrAPI } from "utils/api";

export class EmailReceiversPanel extends React.Component {
  private subscribtions: Subscription = new Subscription();
  public state = { emailReceivers: new Array<EmailReceiverModel>() };
  constructor(props: any) {
    super(props);
    this.updateEmailReceivers = this.updateEmailReceivers.bind(this);
  }

  public componentDidMount(): void {
    this.subscribtions.add(acrAPI.emailReceiversSubject.subscribe(this.updateEmailReceivers));
  }

  public componentWillUnmount(): void {
    this.subscribtions.unsubscribe();
  }

  private updateEmailReceivers(emailReceivers: EmailReceiverModel[]): void {
    this.setState({ emailReceivers });
  }
  public render(): JSX.Element {
    return (
      <Box marginTop={1} marginBottom={2}>
        <Typography variant="h5" data-cy="settings-email-receivers-title">
          {translate("receiversEmail")}
        </Typography>
        <Box marginTop={1}>
          <Typography variant="h6" data-cy="settings-email-add-receiver-title">
            {translate("add an email address")}
          </Typography>
          <AddNewEmailReceiver emailReceivers={this.state.emailReceivers} />
        </Box>
        {this.state.emailReceivers.length === 0 ? (
          <Box marginTop={1}>
            <Typography color="textSecondary" data-cy="settings-email-no-receiver-configured-label">
              {translate("no email configured yet")}
            </Typography>
          </Box>
        ) : (
          <Box>
            <Typography variant="h6" data-cy="settings-email-add-email-receiver-title">
              {translate("email Receiver List")}
            </Typography>
            {this.state.emailReceivers.map((emailReceiver) => (
              <EmailReceiver emailReceiver={emailReceiver} key={emailReceiver.id} />
            ))}
          </Box>
        )}
      </Box>
    );
  }
}
