import React from "react";
import { Subscription } from "rxjs";
import { Formik, Form, Field, FormikTouched } from "formik";
import { Typography, TextField, Button, Box } from "@material-ui/core";
import { object, string } from "yup";
import { translate } from "i18n";
import { acrAPI } from "utils/api";

export class SnmpGlobalConfiguration extends React.Component {
  private subscribtions: Subscription = new Subscription();
  private validationSchema = object({
    communityName: string()
      .required(translate("a community name is required"))
      .max(20, translate("community name is limited to 20 characters")),
  });

  public state = { communityName: "Loading..." };

  constructor(props: any) {
    super(props);
    this.updateCommunityName = this.updateCommunityName.bind(this);
  }

  public componentDidMount(): void {
    this.subscribtions.add(acrAPI.communityNameSubject.subscribe(this.updateCommunityName));
  }

  public componentWillUnmount(): void {
    this.subscribtions.unsubscribe();
  }

  private updateCommunityName(communityName: string): void {
    this.setState({ communityName });
  }

  private canSubmit(errors: any, touched: FormikTouched<{ communityName: string }>): boolean {
    return Object.keys(errors).length === 0 && Object.keys(touched).length > 0;
  }

  private async handleSubmit(values: any): Promise<void> {
    await acrAPI.updateSnmpConfiguration(values);
  }

  public render(): JSX.Element {
    return (
      <Formik
        initialValues={this.state}
        enableReinitialize={true}
        validationSchema={this.validationSchema}
        onSubmit={async (values, { resetForm }) => {
          await this.handleSubmit(values);
          resetForm({ values });
        }}>
        {({ handleSubmit, handleChange, setFieldTouched, touched, values, errors }) => (
          <Form name="settings-snmp-configuration-form" translate="none" method="POST" onSubmit={handleSubmit}>
            <Box>
              <Typography variant="h5" data-cy="settings-snmp-configuration-form-title">
                {translate("Configuration")}
              </Typography>
              <Box display="flex" width={"39em"}>
                <Field
                  component={TextField}
                  variant="outlined"
                  margin="normal"
                  id="communityName"
                  name="communityName"
                  label={translate("community name")}
                  maxLength={20}
                  style={{ width: "23em" }}
                  helperText={errors.communityName}
                  onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                    setFieldTouched("communityName", true);
                    handleChange(e);
                  }}
                  value={values.communityName}
                  data-cy={`snmp-community-name`}
                />
                <Button
                  type="submit"
                  disabled={!this.canSubmit(errors, touched)}
                  variant="contained"
                  data-cy="snmp-settings-configuration-save-button"
                  style={{ margin: "1.25em 0 1.5em 1.5em", width: "12em", height: "2.5em", padding: "0 2em" }}
                  color="primary">
                  {translate("save")}
                </Button>
              </Box>
            </Box>
          </Form>
        )}
      </Formik>
    );
  }
}
