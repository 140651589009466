import React from "react";
import axios from "axios";
import { Box, Typography, Button } from "@material-ui/core";

import { Language } from "../../models/LanguageModel";

import { withStyles } from "@material-ui/core/styles";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import { translate } from "i18n";
import FlagIcon from "../../components/FlagIconFactory";

import ToggleButton from "@material-ui/lab/ToggleButton";

const StyledToggleButtonGroup = withStyles((theme) => ({
  grouped: {
    "height": "8em",
    "border": "none",
    "padding": theme.spacing(0, 1),
    "&:not(:first-child)": {
      borderRadius: theme.shape.borderRadius,
    },
    "&:first-child": {
      borderRadius: theme.shape.borderRadius,
    },
  },
}))(ToggleButtonGroup);

export class LanguageSwitcher extends React.Component {
  public state = { language: localStorage.getItem("language") };
  private languages = [
    {
      name: "English",
      icon_id: "gb",
      lang_id: "en",
    },
    {
      name: "French",
      icon_id: "fr",
      lang_id: "fr",
    } /*,
        {
          name: 'Swedish',
          icon_id: 'se',
          lang_id: 'se'
        }*/,
  ];
  private handleLanguage = (event: React.MouseEvent<HTMLElement>, newLanguage: string) => {
    this.setState({ language: newLanguage });
  };
  private async handleLanguageSave(): Promise<void> {
    const data = {
      login: localStorage.getItem("login"),
      language: this.state.language,
    };

    if (data.language) {
      localStorage.setItem("language", data.language);
    }
    await axios.patch(`/user/language`, data).catch((e) => {
      console.log("Failed to update password: ", e);
    });
  }
  public render(): JSX.Element {
    return (
      <Box marginLeft={2} p={2} style={{ width: "50em" }}>
        <Box marginBottom={2}>
          <Typography variant="h4" data-cy="settings-language-label">
            {translate("language")}
          </Typography>
        </Box>
        <Box display="flex" flexDirection="row" alignItems="center">
          <StyledToggleButtonGroup
            size="small"
            value={this.state.language}
            exclusive
            onChange={this.handleLanguage}
            aria-label="text alignment">
            {this.languages.map((language: Language) => (
              <ToggleButton
                value={language.lang_id}
                aria-label={language.name}
                key={language.name}
                data-cy={`settings-flag-${language.lang_id}-button`}>
                <Box component="div" p="1rem">
                  <FlagIcon code={language.icon_id} size="3x" />
                </Box>
              </ToggleButton>
            ))}
          </StyledToggleButtonGroup>
        </Box>
        <Button
          onClick={() => this.handleLanguageSave()}
          variant="contained"
          color="primary"
          fullWidth
          id="saveLanguage"
          disabled={this.state.language === localStorage.getItem("language")}
          data-cy="settings-save-language-button"
          style={{ marginTop: "1em" }}>
          {translate("save")}
        </Button>
      </Box>
    );
  }
}
