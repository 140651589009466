import { Route, Redirect } from "react-router-dom";
import { loginService } from "../utils/login";
import { Mosaic } from "../pages/Mosaic";
import React from "react";

export function PrivateRoute({ ...rest }: any): React.ReactElement {
  if (rest.path === "/") {
    window.location.href = "/mosaic";
    return <Route exact path="/mosaic" component={Mosaic} />;
  }
  const isAuthValid = loginService.verifyAuthentification();
  if (isAuthValid) {
    return <rest.component {...rest} {...rest.location.state} />;
  }
  return (
    <Redirect
      {...rest}
      {...rest.location.state}
      to={{
        pathname: "/login",
        state: { referrer: rest.path, ...rest.location.state },
      }}
    />
  );
}
