import React, { useState, ChangeEvent, KeyboardEvent, useEffect, useCallback, useRef } from "react";

import axios from "axios";
import { useTranslation } from "react-i18next";

import { Typography, Box, Paper, RootRef } from "@material-ui/core";

import { SoftwareLogTable } from "../components/Logs/SoftwareLogTable";
import { SoftwareLog } from "../models/SoftwareLogModel";
import { Refresh } from "../components/Logs/Header/Refresh";
import { LogPerPage } from "../components/Logs/Header/LogPerPage";
import { Search } from "../components/Logs/Header/Search";
import { LogLevel } from "../components/Logs/Header/LogLevel";
import Pagination from "../components/Logs/Header/Pagination";
import { Download } from "../components/Logs/Header/Download";
import { LogModulesFilter } from "../components/Logs/Header/LogModulesFilter";

import { moduleTypes } from "acr-common-models";

interface ILogsInterfaceProps {
  channelId: number;
  channelName?: string;
}

export interface ISoftwareLogRequestParameters {
  currentLogPerPage: number;
  currentLogLevel: number;
  currentPageNumber: number | "";
  moduleTypes: Array<number> | undefined;
  encodedSearch: string;
}

export type OrderType = "asc" | "desc";

export function SoftwareLogs(logsInterfaceProps: ILogsInterfaceProps): React.ReactElement {
  const ROUTE_PREFIX = "softwareLog";
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [orderType, setOrderType] = useState<OrderType>("desc");
  const [orderBy, setOrderBy] = useState<keyof SoftwareLog>("date");
  const [logLevel, setLogLevel] = useState(1);
  const [pageNumber, setPageNumber] = useState(1);
  const [logPerPage, setLogPerPage] = useState(25);
  const [logCount, setLogCount] = useState(0);
  const [softwareLogs, setSoftwareLogs] = useState<Array<SoftwareLog>>(new Array<SoftwareLog>());
  const totalPageCount = Math.floor(logCount / logPerPage) + 1;
  const [searchQuery, setSearchQuery] = useState("");
  const [containerHeight, setContainerHeight] = useState(0);
  const channelId = logsInterfaceProps ? logsInterfaceProps.channelId : undefined;

  const isLogPage = window.location.href.indexOf("logs") > 0;
  const pagePadding = isLogPage ? "2em" : "0em";

  const headerRef = useRef<HTMLDivElement>();
  const topRef = useRef<HTMLDivElement>();

  const [categoryName, setCategoryName] = React.useState<string[]>(moduleTypes.map((moduleType) => moduleType.title));
  const [categoriesUpdateSwitch, setCategoriesUpdateSwitch] = useState(false);

  const showChannelName = logsInterfaceProps.channelId === undefined;
  // We calculate the height of the container to make the scrollbar inside the page
  const resizeContainer = useCallback(() => {
    if (headerRef.current) {
      let headerHeight: number = headerRef.current.getBoundingClientRect().height;
      if (headerRef.current.parentElement?.parentElement?.parentElement) {
        //this table can be displayed in two places (LOGS tab and channel detail)
        const isLogPage = window.location.href.indexOf("logs");
        let pageHeight: number = 0;
        if (isLogPage > -1) {
          pageHeight = headerRef.current.parentElement.parentElement.parentElement.getBoundingClientRect().height;
        } else if (headerRef.current.parentElement.parentElement.parentElement.parentElement) {
          pageHeight = headerRef.current.parentElement.parentElement.parentElement.parentElement.getBoundingClientRect().height;
        }
        if (topRef.current?.parentElement?.parentElement?.parentElement) {
          if (window.location.href.indexOf("logs") > -1) {
            headerHeight = headerRef.current.getBoundingClientRect().height || 80;
            pageHeight = topRef.current.parentElement.parentElement.parentElement.getBoundingClientRect().height;
            setContainerHeight(pageHeight - (145 + headerHeight + (isLogPage ? 0 : 75)));
          } else if (topRef.current.parentElement.parentElement.parentElement.parentElement) {
            headerHeight = headerRef.current.getBoundingClientRect().height || 80;
            pageHeight = topRef.current.parentElement.parentElement.parentElement.parentElement.getBoundingClientRect().height;
            setContainerHeight(pageHeight - (145 + headerHeight + (isLogPage ? 0 : 125)));
          }
        }
      }
    }
  }, [topRef, headerRef]);

  const fetchSoftwareLogs = useCallback(
    async (softWareLogRequestParameters: ISoftwareLogRequestParameters = {} as ISoftwareLogRequestParameters) => {
      if (isNaN(softWareLogRequestParameters.currentLogLevel)) {
        softWareLogRequestParameters.currentLogLevel = logLevel;
      }
      if (!softWareLogRequestParameters.currentLogPerPage) {
        softWareLogRequestParameters.currentLogPerPage = logPerPage;
      }
      if (!softWareLogRequestParameters.currentPageNumber) {
        softWareLogRequestParameters.currentPageNumber = pageNumber;
      }
      if (!softWareLogRequestParameters.encodedSearch) {
        softWareLogRequestParameters.encodedSearch = encodeURIComponent(searchQuery);
      }
      if (!softWareLogRequestParameters.currentPageNumber || softWareLogRequestParameters.currentPageNumber === 0) {
        return;
      }
      let searchURL = `/${ROUTE_PREFIX}/?orderBy=${orderBy}&orderType=${orderType}&count=${softWareLogRequestParameters.currentLogPerPage}`;
      searchURL += `&pageNumber=${softWareLogRequestParameters.currentPageNumber}&level=${softWareLogRequestParameters.currentLogLevel}`;

      if (softWareLogRequestParameters.encodedSearch.length > 0) {
        searchURL += `&searchQuery=${softWareLogRequestParameters.encodedSearch}`;
      }
      if (channelId) {
        searchURL += `&channelId=${channelId}`;
      }
      const categoryList: Array<number | undefined> = categoryName.map(
        (category) => moduleTypes.find((moduleType) => category === moduleType.title)?.id
      );
      if (categoryList.length > 0) {
        for (var i=0; i< categoryList.length; i++) {
          searchURL+="&moduleTypes="+categoryList[i];
        }

        await axios
          .get(searchURL)
          .then(async (logs) => {
            setSoftwareLogs(logs.data.logs);
            setLogCount(logs.data.count - 1);
            if (
              softWareLogRequestParameters.currentPageNumber &&
              logs.data.count / logPerPage < softWareLogRequestParameters.currentPageNumber
            ) {
              setPageNumber(Math.floor(logs.data.count / logPerPage) + 1);
            }
          })
          .catch((e) => {
            console.log("Error while getting channel list:", e.error);
          });
      } else {
        // No moduleType: no log
        setSoftwareLogs(new Array<SoftwareLog>());
        setLogCount(0);
        setPageNumber(1);
      }
      setLoading(false);
    },
    // eslint-disable-next-line
    [channelId, logLevel, logPerPage, orderBy, orderType, categoriesUpdateSwitch]
  );

  const getSoftwareLogs = useCallback(
    async (softWareLogRequestParameters: ISoftwareLogRequestParameters = {} as ISoftwareLogRequestParameters) => {
      if (loading) {
        return;
      }
      setLoading(true);
      await fetchSoftwareLogs(softWareLogRequestParameters);
      setLoading(false);
    },
    //TODO find a nicer way to fetch data to avoid the eslint-disable-next-line => axios hook? async hook?
    // https://www.npmjs.com/package/axios-hooks
    // eslint-disable-next-line
    [fetchSoftwareLogs]
  );

  const handlePageNumberChange = async (event: React.ChangeEvent<{ value: unknown }>) => {
    let value: string = event.target.value as string;
    let newPageNumber: number | null = Number(value.replace(/[^0-9]/g, ""));
    if (newPageNumber > totalPageCount) {
      newPageNumber = totalPageCount;
    } else if (newPageNumber <= 0) {
      newPageNumber = 1;
    }
    if (pageNumber === newPageNumber) {
      return;
    }
    setPageNumber(newPageNumber);
    getSoftwareLogs({
      currentPageNumber: newPageNumber,
      encodedSearch: encodeURIComponent(searchQuery),
    } as ISoftwareLogRequestParameters);
  };

  function onPaginationKeyUp(event: KeyboardEvent<HTMLDivElement>): void {
    // Key enter (13)
    if (event.keyCode === 13) {
      getSoftwareLogs({
        currentPageNumber: pageNumber,
        encodedSearch: encodeURIComponent(searchQuery),
      } as ISoftwareLogRequestParameters);
    }
  }

  const handleCategoryChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setCategoryName(event.target.value as string[]);
  };

  const handleCategoryClose = (event: React.ChangeEvent<{}>) => {
    setPageNumber(1);
    setCategoriesUpdateSwitch(!categoriesUpdateSwitch);
  };

  const handleLogLevelChange = async (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
    const value: number = event.target.value as number;
    setLogLevel(value);
    setPageNumber(1);
  };

  const handleLogPerPageChange = async (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
    const newLogPerPage: number = event.target.value as number;
    let currentVisibleLog = Math.floor((pageNumber - 1) * logPerPage) + 1;
    let newPage = Math.floor(currentVisibleLog / newLogPerPage) + 1;
    setLogPerPage(newLogPerPage);
    setPageNumber(newPage);
  };

  const handleOrderByUpdate = (newOrderBy: keyof SoftwareLog) => setOrderBy(newOrderBy);

  const handleNextPage = () => {
    if (logCount > 0) {
      const currentPageNumber = pageNumber ? pageNumber + 1 : 1;
      setPageNumber(currentPageNumber);
      getSoftwareLogs({
        currentPageNumber: currentPageNumber,
        encodedSearch: encodeURIComponent(searchQuery),
      } as ISoftwareLogRequestParameters);
    }
  };

  const handlePreviousPage = () => {
    const currentPageNumber = pageNumber ? pageNumber - 1 : 1;
    setPageNumber(currentPageNumber);
    getSoftwareLogs({
      currentPageNumber: currentPageNumber,
      encodedSearch: encodeURIComponent(searchQuery),
    } as ISoftwareLogRequestParameters);
  };

  const onSearchKeyUp = async (event: KeyboardEvent<HTMLDivElement>) => {
    // ENTER (13)
    if (event.keyCode === 13) {
      setPageNumber(1);
      await fetchSoftwareLogs({ encodedSearch: encodeURIComponent(searchQuery) } as ISoftwareLogRequestParameters);
    }
  };

  const onSearchQueryChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
  };

  const refreshSearch = async () => {
    setLoading(true);
    await fetchSoftwareLogs({ encodedSearch: encodeURIComponent(searchQuery) } as ISoftwareLogRequestParameters);
    setPageNumber(1);
    setLoading(false);
  };

  window.onresize = () => {
    resizeContainer();
  };

  useEffect(() => {
    getSoftwareLogs();
    resizeContainer();
    // eslint-disable-next-line
  }, [orderType, orderBy, logLevel, logPerPage, categoriesUpdateSwitch, resizeContainer, getSoftwareLogs]);

  return (
    <RootRef rootRef={topRef}>
      <Box
        style={{
          width: "100%",
          justifyContent: "space-between",
          padding: pagePadding,
        }}
      >
        <Paper elevation={4}>
          <Box display="flex" flexDirection="column" alignItems="center" justifyContent="space-between">
            <RootRef rootRef={headerRef}>
              <Box
                display="flex"
                flexDirection="column"
                flexWrap="wrap"
                style={{
                  width: "98%",
                  paddingTop: "8px",
                }}
              >
                <Box display="flex">
                  <Pagination
                    handleNextPage={handleNextPage}
                    handlePageNumberChange={handlePageNumberChange}
                    onPaginationKeyUp={onPaginationKeyUp}
                    handlePreviousPage={handlePreviousPage}
                    pageNumber={pageNumber}
                    totalPageCount={totalPageCount}
                  />
                  <LogPerPage logPerPage={logPerPage} handleLogPerPageChange={handleLogPerPageChange} />
                  <Refresh refreshSearch={refreshSearch} disabled={loading} />
                  {!channelId && (
                    <LogModulesFilter
                      logModules={moduleTypes}
                      onChange={handleCategoryChange}
                      onClose={handleCategoryClose}
                      categoryName={categoryName}
                    />
                  )}
                  <LogLevel logLevel={logLevel} handleLogLevelChange={handleLogLevelChange} />
                  <Search
                    onSearchKeyUp={onSearchKeyUp}
                    onSearchQueryChange={onSearchQueryChange}
                    refreshSearch={refreshSearch}
                    searchQuery={searchQuery}
                  />
                  <Download
                    logType="softwareLog"
                    channelId={logsInterfaceProps.channelId}
                    logLevel={logLevel}
                    encodedSearchQuery={encodeURIComponent(searchQuery)}
                    orderBy={orderBy}
                    orderType={orderType}
                    categoryName={categoryName}
                  />
                </Box>
              </Box>
            </RootRef>
            <Box style={{ width: "100%" }}>
              {loading ? (
                <Box display="flex" justifyContent="center" alignItems="center" m={5} p={5}>
                  <Typography variant="h4">{t("loading")}</Typography>
                </Box>
              ) : (
                <SoftwareLogTable
                  softwareLogs={softwareLogs}
                  getSotwareLogs={getSoftwareLogs}
                  orderBy={orderBy}
                  orderType={orderType}
                  handleOrderTypeUpdate={setOrderType}
                  handleOrderByUpdate={handleOrderByUpdate}
                  showChannelName={showChannelName}
                  containerHeight={containerHeight}
                  loading={loading}
                  logModules={moduleTypes}
                />
              )}
            </Box>
          </Box>
        </Paper>
      </Box>
    </RootRef>
  );
}
