import React, { useState } from "react";

import { Redirect } from "react-router";

import { Button, makeStyles, createStyles, Box } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";

const useStyles = makeStyles((_) =>
  createStyles({
    container: {
      height: "8rem",
      minWidth: "8rem",
      maxWidth: "8rem",
      margin: "20px",
      border: "2px round black",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
  })
);

interface ICreateChannelProps {
  disabled: boolean;
}

export function CreateChannelButton(createChannelButtonProps: ICreateChannelProps): React.ReactElement {
  const [redirectCreate, setRedirectCreate] = useState(false);
  const classes = useStyles();
  function handleCreate(): void {
    setRedirectCreate(true);
    window.history.pushState({}, "/mosaic");
  }
  return (
    <Paper elevation={4} style={{ margin: "4px 12px " }}>
      <Box className={classes.container}>
        <Button color="inherit" onClick={handleCreate} disabled={createChannelButtonProps.disabled}>
          <h1>&nbsp;&nbsp;&nbsp;&nbsp;+&nbsp;&nbsp;&nbsp;&nbsp;</h1>
        </Button>
      </Box>

      {redirectCreate ? <Redirect to="/channelDetail/create" /> : null}
    </Paper>
  );
}
