import React from "react";
import { Redirect } from "react-router";
import { Box, Paper, Typography, Tooltip, Zoom } from "@material-ui/core";

import { Channel, channelTypes, CHANNEL_STATUS } from "../../models/ChannelModel";
import { LED_COLORS } from "../../utils/colors";
import { translate } from "../../i18n";

interface IChannelSquareProps {
  channel: Channel;
  validLicense: boolean;
  mosaicIndex: number;
  qualityIndexEnable: string;
}

export class ChannelSquare extends React.Component<IChannelSquareProps> {
  public state = { editRedirect: false };

  constructor(props: any) {
    super(props);
    this.props = props;
    this.cyChannelSquareId = `channel-square-${props.mosaicIndex}`;
  }

  //used in data-cy attributes
  private cyChannelSquareId = "";

  public props: any;

  private lastTSDate = (timestamp: any) => {
    return !timestamp ? "N/A" : timestamp.substring(0, timestamp.indexOf("T"));
  };
  private lastTSTime = (timestamp: any) => {
    return !timestamp ? "N/A" : timestamp.substring(timestamp.indexOf("T") + 1, timestamp.lastIndexOf("."));
  };

  private handleEdit(): void {
    this?.setState({ editRedirect: true });
  }
  private getBackgroundColor = (state: CHANNEL_STATUS) => {
    switch (state) {
      case CHANNEL_STATUS.OK:
        return LED_COLORS.OK.BACKGROUND.ENABLED;
      case CHANNEL_STATUS.ERROR:
        return LED_COLORS.ERROR.BACKGROUND.ENABLED;
      case CHANNEL_STATUS.WARNING:
        return LED_COLORS.WARNING.BACKGROUND.ENABLED;
      case CHANNEL_STATUS.PENDING:
        return LED_COLORS.WAITING.BACKGROUND.ENABLED;
      case CHANNEL_STATUS.STOP:
        return LED_COLORS.STOP.BACKGROUND.ENABLED;
      default:
        console.log(`getBackgroundColor default: error`);
        return CHANNEL_STATUS.ERROR;
    }
  };
  private getFontColor = (state: CHANNEL_STATUS) => {
    switch (state) {
      case CHANNEL_STATUS.OK:
        return LED_COLORS.OK.FONT.ENABLED;
      case CHANNEL_STATUS.ERROR:
        return LED_COLORS.ERROR.FONT.ENABLED;
      case CHANNEL_STATUS.WARNING:
        return LED_COLORS.WARNING.FONT.ENABLED;
      case CHANNEL_STATUS.PENDING:
        return LED_COLORS.WAITING.FONT.ENABLED;
      case CHANNEL_STATUS.STOP:
        return LED_COLORS.STOP.FONT.ENABLED;  
    }
  };

  private getAlarmText = (alarm?: boolean) => {
    if (alarm === undefined || alarm === null) {
      return "N/A";
    }
    if (alarm) {
      return translate("Error");
    }
    return translate("OK");
  };

  private formatedChannelName = () => {
    const channelName = this.props.channel.name;
    return channelName.length <= 21 ? channelName : channelName.substring(0, 18) + "...";
  };

  private capitalize = (str: string) => str.charAt(0).toUpperCase() + str.slice(1);

  private toolTipText = (alarms: any, qualityIndexEnable: boolean) => (
    <div>
      <p>
        {translate("no signal")}: {this.getAlarmText(alarms?.noSignal)}
      </p>
      <p>
        {translate("no watermark")}: {this.getAlarmText(alarms?.noWatermark)}
      </p>
      <p>
        {translate("unexpected watermark")}: {this.getAlarmText(alarms?.unexpectedWatermark)}
      </p>
      <p>
        {translate("timestamp drift")}: {this.getAlarmText(alarms?.timestampDrift)}
      </p>
      {qualityIndexEnable && (
        <p>
          {translate("Quality index")}: {alarms?.qualityIndexStatus ? this.capitalize(alarms?.qualityIndexStatus) : "N/A"}
        </p>
      )}
    </div>
  );
  public render(): JSX.Element {
    return (
      <Box m={1} key={this.props.channel.id} style={{ userSelect: "none" }} onClick={() => this.handleEdit()}>
        {this.state.editRedirect ? (
          <Redirect
            to={{
              pathname: "/channelDetail/",
              state: { id: this.props.channel ? this.props.channel.id : -1 },
            }}
          />
        ) : null}

        <Paper elevation={4}>
          <Tooltip
            enterDelay={1000}
            leaveDelay={0}
            TransitionComponent={Zoom}
            disableFocusListener
            arrow
            title={this.toolTipText(this.props.channel.alarms, this.props.qualityIndexEnable)}
            placement="bottom">
            <Box
              key={`Channel_${this.props.channel.id}`}
              bgcolor={this.getBackgroundColor(this.props.channel.status)}
              color={this.getFontColor(this.props.channel.status)}
              data-cy={`${this.cyChannelSquareId}`}
              style={{
                position: "relative",
                minHeight: "9rem",
                minWidth: "9rem",
                maxHeight: "9rem",
                maxWidth: "9rem",
                cursor: "pointer",
                display: "flex",
                flexGrow: 1,
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
                padding: "1em",
              }}>
              <Typography
                variant="h4"
                style={{ textAlign: "center", wordBreak: "break-word", lineHeight: "1.2", width: "120px" }}
                data-cy={`${this.cyChannelSquareId}-name`}>
                {this.formatedChannelName()}
              </Typography>
              <Typography
                data-cy={`${this.cyChannelSquareId}-type`}
                variant="body2"
                align="center"
                style={{ fontWeight: "bold" }}>
                {channelTypes.name(this.props.channel.channelType)}
              </Typography>
              {this.props.validLicense ? (
                <>
                  <Typography data-cy={`${this.cyChannelSquareId}-detected-wmk-id`} variant="body2" align="center">
                    {this.props.channel.lastWatermarkId > 0 && this.props.channel.lastWatermarkId}
                  </Typography>
                  <Typography data-cy={`${this.cyChannelSquareId}-timestamp-date`} variant="body2" align="center">
                    {this.lastTSDate(this.props.channel.lastTimestamp)}
                  </Typography>
                  <Typography data-cy={`${this.cyChannelSquareId}-timestamp-time`} variant="body2" align="center">
                    {this.lastTSTime(this.props.channel.lastTimestamp)}
                  </Typography>
                </>
              ) : (
                <Typography variant="body2" align="center">
                  {translate("license invalid")}
                </Typography>
              )}
            </Box>
          </Tooltip>
        </Paper>
      </Box>
    );
  }
}
