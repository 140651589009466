import { acrMonitorColors } from "./colors";

import { createTheme, makeStyles, createStyles } from "@material-ui/core/styles";

export const theme = createTheme({
  palette: {
    primary: {
      main: acrMonitorColors.PRIMARY_MAIN,
      dark: acrMonitorColors.PRIMARY_DARK,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary: {
      main: acrMonitorColors.SECONDARY_MAIN,
      dark: acrMonitorColors.SECONDARY_DARK,
    },
  },
  typography: {
    body1: {
      fontSize: "12px",
    },
    h3: {
      fontSize: "32px",
    },
    h4: {
      fontSize: "18px",
    },
    h5: {
      fontSize: "16px",
    },
    h6: {
      fontSize: "14px",
      color: "#555",
    },
    fontFamily: [
      "Arial",
      "Roboto",
      '"Helvetica Neue"',
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
  },
  overrides: {
    MuiTable: {
      root: {},
    },
    MuiTableCell: {
      root: {
        padding: "5px",
      },
    },
    MuiSvgIcon: {
      root: {
        width: "0.75em",
        height: "0.75em",
      },
    },
    MuiAppBar: {
      root: {
        color: "rgba(0, 0, 0, 0.54)",
        fontSize: "14px",
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        textTransform: "uppercase",
      },
      positionStatic: {
        height: "48px",
      },
    },
    MuiTab: {
      root: {
        minWidth: "60px",
        paddinTop: "0",
      },
    },
    MuiTabs: {
      root: {
        minWidth: "70px",
        overflow: "unset",
      },
      fixed: {
        overflow: "unset!important" as any,
      },
    },
    MuiSnackbar: {
      root: {},
    },
    MuiSnackbarContent: {
      root: {},
      message: {},
    },
    MuiInputLabel: {
      root: { transform: "translate(0, 1.5px) scale(1)" },
      outlined: { transform: "translate(14px, 15px) scale(1)" },
    },
    MuiOutlinedInput: {
      input: { padding: "12.5px 14px" },
    },
    MuiFormHelperText: {
      contained: { color: "#f44336" },
    },
    MuiRadio: {     
      root: {
        color: "black",
      },
      colorSecondary: {
        '&$checked': {
          color: "black",
        },
      },
    },
  },
});

export const dropDownStyles = makeStyles((_) =>
  createStyles({
    container: {
      margin: "0 8px 8px 8px",
      padding: "0 8px 8px 8px",
    },
    textField: {
      fontSize: "11px",
      textAlign: "center",
      width: "100%",
    },
    miniLabel: {
      color: "#FF0000",
      fontSize: "14px !important",
      textAlign: "center",
      width: "100%",
      margin: "2px",
    },
    formControl: { width: "7.3rem" },
  })
);
