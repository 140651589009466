import React, { useState, ChangeEvent, FormEvent } from "react";

import { useTranslation } from "react-i18next";
import { Box, Typography, TextField, Button, FormGroup } from "@material-ui/core";
import * as owasp from "owasp-password-strength-test";

import { loginService } from "utils/login";

//TODO BCO
// => Convert this component to a class component
// => use formik for validation
export function PasswordUpdater(): JSX.Element {
  const { t } = useTranslation();
  const [badCredential, setBadCredential] = useState(false);
  const [oldPassword, setOldPassword] = useState("");
  const [firstNewPassword, setNewFirstPassword] = useState("");
  const [secondNewPassword, setNewSecondPassword] = useState("");
  const [differentPasswords, setDifferentPasswords] = useState(false);
  const [badPasswordFormat, setBadPasswordFormat] = useState(false);
  const [samePassword, setSamePassword] = useState(false);
  const [passwordUpdatedSuccessfully, setPasswordUpdatedSuccessfully] = useState(false);

  const checkPasswordValidity = (password: string) => {
    //Only the first password format is check as we also check that both are identical
    //If the user did not touch to the first password we do not display format error
    if (password.length === 0) {
      setBadPasswordFormat(false);
    }
    const result = owasp.test(password);
    return setBadPasswordFormat(result.errors.length > 0);
  };

  const checkPasswordEquality = (firstPassword: string, secondPassword: string) =>
    setDifferentPasswords(firstPassword !== secondPassword);

  const checkSamePassword = (oldPassword: string, firstNewPassword: string, secondNewPassword: string) => {
    setSamePassword(firstNewPassword === secondNewPassword && oldPassword === secondNewPassword);
  };

  const onOldPasswordUpdate = (event: ChangeEvent<HTMLInputElement>) => {
    setOldPassword(event.target.value);
    checkSamePassword(oldPassword, firstNewPassword, event.target.value);
  };

  const onNewFirstPasswordUpdate = (event: ChangeEvent<HTMLInputElement>) => {
    setNewFirstPassword(event.target.value);
    checkPasswordEquality(event.target.value, secondNewPassword);
    checkPasswordValidity(event.target.value);
    checkSamePassword(oldPassword, event.target.value, secondNewPassword);
  };
  const onNewSecondPasswordUpdate = (event: ChangeEvent<HTMLInputElement>) => {
    setNewSecondPassword(event.target.value);
    checkPasswordEquality(firstNewPassword, event.target.value);
    checkSamePassword(oldPassword, firstNewPassword, event.target.value);
  };
  const handlePasswordChange = async (event: FormEvent) => {
    event.preventDefault();
    checkPasswordEquality(firstNewPassword, secondNewPassword);
    if (!badPasswordFormat && !differentPasswords) {
      await loginService
        .updatePassword(oldPassword, firstNewPassword)
        .then((res) => {
          if (!res) {
            setBadCredential(true);
          } else {
            setPasswordUpdatedSuccessfully(true);
            setBadCredential(false);
            setOldPassword("");
            setNewFirstPassword("");
            setNewSecondPassword("");
            //As the password is included in jwt token we have to update it
            const { token } = res.data;
            localStorage.setItem("access_token", token);
          }
        })
        .catch((e) => console.log("Failed to change password:", e));
    }
  };
  return (
    <Box marginLeft={2} p={2} style={{ width: "50em" }}>
      <FormGroup>
        <Box>
          <Typography data-cy="settings-password-update-label" variant="h4">
            {t("password update")}
          </Typography>
        </Box>
        {passwordUpdatedSuccessfully ? (
          <Typography>{t("password updated successfully")}</Typography>
        ) : (
          <form onSubmit={handlePasswordChange}>
            <Box flexDirection="column" display="flex">
              <TextField
                variant="outlined"
                margin="normal"
                required
                label={t("old password")}
                name="old password"
                type="password"
                onChange={onOldPasswordUpdate}
                autoFocus
                data-cy="settings-old-password-update"
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                name="newFirstPassword"
                label={t("new password")}
                onChange={onNewFirstPasswordUpdate}
                type="password"
                data-cy="settings-new-password-update"
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                name="newSecondPassword"
                label={t("verify new password")}
                onChange={onNewSecondPasswordUpdate}
                type="password"
                data-cy="settings-retype-new-password-update"
              />
              {badCredential && <Typography color="error">{t("bad password")}</Typography>}
              {badPasswordFormat && <Typography color="error">{t("bad password format")}</Typography>}
              {differentPasswords && <Typography color="error">{t("different passwords")}</Typography>}
              {samePassword && <Typography color="error">{t("enter a new password")}</Typography>}
              <Button
                type="submit"
                id="formSubmit"
                disabled={oldPassword.length === 0 || differentPasswords || badPasswordFormat || samePassword}
                variant="contained"
                data-cy="settings-update-password-button"
                color="primary">
                {t("update password")}
              </Button>
            </Box>
          </form>
        )}
      </FormGroup>
    </Box>
  );
}
