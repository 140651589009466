import React from "react";
import { Subscription } from "rxjs";
import { Button, TextField, Box } from "@material-ui/core";
import { translate } from "i18n";
import { EmailReceiverModel } from "acr-common-models";
import { Formik, Form, Field, FormikTouched } from "formik";
import { emailReceiverValidationSchema } from "./EmailReceiver";
import { acrAPI } from "utils/api";
import { EmailReceiverFormErrors } from "./EmailReceiverFormErrors";

interface IAddNewEmailReceiverProps {
  emailReceivers: Array<EmailReceiverModel>;
}

export class AddNewEmailReceiver extends React.Component<IAddNewEmailReceiverProps> {
  private formId = "settings-email-new-emailReceiver-form";
  private subscribtions: Subscription = new Subscription();
  public initialEmailReceiverState = { emailAddress: "", id: 0 };
  public state = { ...this.initialEmailReceiverState, conflict: false, errorNbEmail: false };

  private emailAddressFieldId = "emailAddress";

  private canSubmit(errors: any, touched: FormikTouched<{ emailAddress: string }>): boolean {
    return Object.keys(errors).length === 0 && Object.keys(touched).length > 0;
  }
  constructor(props: any) {
    super(props);
    this.updateEmailReceiverConflict = this.updateEmailReceiverConflict.bind(this);
  }
  public componentDidMount(): void {
    this.subscribtions.add(acrAPI.emailReceiversSubject.subscribe(this.updateEmailReceiverConflict));
  }

  public componentWillUnmount(): void {
    this.subscribtions.unsubscribe();
  }

  private updateEmailReceiverConflict(emailReceivers: EmailReceiverModel[]): void {
    const conflictEmailReceiver = emailReceivers.find(
      (emailReceiver) => emailReceiver.emailAddress === this.state.emailAddress);
    this.setState({ conflict: conflictEmailReceiver !== undefined });
  }

  private async handleSubmit(values: any): Promise<void> {
    const newEmailReceiver = { emailAddress: values.emailAddress } as EmailReceiverModel;
    const conflict = await acrAPI.addEmailReceiver(newEmailReceiver);
    const errorNbEmail = await acrAPI.getEmailConfiguration();
    if (conflict) {
      this.setState({ ...this.state, conflict, errorNbEmail });
    } else {
      this.setState({ ...this.initialEmailReceiverState, conflict, errorNbEmail });
    }
  }
  public render(): JSX.Element {
    return (
      <Formik
        initialValues={this.state}
        validationSchema={emailReceiverValidationSchema}
        onSubmit={async (values) => {
          await this.handleSubmit(values);
        }}>
        {({ handleSubmit, setFieldValue, validateField, setFieldTouched, handleChange, touched, errors, values }) => (
          <Form name={this.formId} data-cy={this.formId} translate="none" method="POST" onSubmit={handleSubmit}>
            <Box display={"flex"} width={"40em"}>
              <Field
                  component={TextField}
                  variant="outlined"
                  margin="normal"
                  label="Your email address"
                  name={this.emailAddressFieldId}
                  id={this.emailAddressFieldId}
                  style={{ width: "18em", marginRight: "2em" }}
                  value={values.emailAddress}
                  data-cy={this.emailAddressFieldId}
                  onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                    setFieldValue(this.emailAddressFieldId, e.target.value);
                    setFieldTouched(this.emailAddressFieldId, true, true);
                    handleChange(e);
                    validateField(this.emailAddressFieldId);
                    this.setState({ conflict: false, errorNbEmail: false });
                }}
              />
              <Button
                type="submit"
                disabled={!this.canSubmit(errors, touched)}
                variant="contained"
                data-cy="snmp-settings-email-receiver-create-button"
                style={{ width: "15.5em", margin: "1.25em 0em 1.5em 0em" }}
                color="primary">
                {translate("Add")}
              </Button>

            </Box>
            <EmailReceiverFormErrors errors={errors} conflict={this.state.conflict} errorNbEmail={this.state.errorNbEmail} />
          </Form>
        )}
      </Formik>
    );
  }
}
