import React from "react";
import { Subscription } from "rxjs";
import { Button, TextField, Box } from "@material-ui/core";
import { translate } from "i18n";
import { TrapReceiverModel } from "acr-common-models";
import { Formik, Form, Field, FormikTouched } from "formik";
import { trapReceiverValidationSchema } from "./TrapReceiver";
import { acrAPI } from "utils/api";
import { TrapReceiverFormErrors } from "./TrapReceiverFormErrors";

interface IAddNewTrapReceiverProps {
  trapReceivers: Array<TrapReceiverModel>;
}

export class AddNewTrapReceiver extends React.Component<IAddNewTrapReceiverProps> {
  private formId = "settings-snmp-new-trapReceiver-form";
  private subscribtions: Subscription = new Subscription();
  public initialTrapReceiverState = { ipAddress: "", port: 0, id: 0 };
  public state = { ...this.initialTrapReceiverState, conflict: false };

  private portFieldId = "port";
  private ipAddressFieldId = "ipAddress";

  private canSubmit(errors: any, touched: FormikTouched<{ ipAddress: string; port: number }>): boolean {
    return Object.keys(errors).length === 0 && Object.keys(touched).length > 0;
  }
  constructor(props: any) {
    super(props);
    this.updateTrapReceiverConflict = this.updateTrapReceiverConflict.bind(this);
  }
  public componentDidMount(): void {
    this.subscribtions.add(acrAPI.trapReceiversSubject.subscribe(this.updateTrapReceiverConflict));
  }

  public componentWillUnmount(): void {
    this.subscribtions.unsubscribe();
  }

  private updateTrapReceiverConflict(trapReceivers: TrapReceiverModel[]): void {
    const conflictTrapReceiver = trapReceivers.find(
      (trapReceiver) => trapReceiver.ipAddress === this.state.ipAddress && trapReceiver.port === this.state.port
    );
    this.setState({ conflict: conflictTrapReceiver !== undefined });
  }

  private async handleSubmit(values: any): Promise<void> {
    const newTrapReceiver = { ipAddress: values.ipAddress, port: values.port } as TrapReceiverModel;
    const conflict = await acrAPI.addTrapReceiver(newTrapReceiver);
    if (conflict) {
      this.setState({ ...this.state, conflict });
    } else {
      this.setState({ ...this.initialTrapReceiverState, conflict });
    }
  }
  public render(): JSX.Element {
    return (
      <Formik
        initialValues={this.state}
        validationSchema={trapReceiverValidationSchema}
        onSubmit={async (values) => {
          await this.handleSubmit(values);
        }}>
        {({ handleSubmit, setFieldValue, validateField, setFieldTouched, handleChange, touched, errors, values }) => (
          <Form name={this.formId} data-cy={this.formId} translate="none" method="POST" onSubmit={handleSubmit}>
            <Box display={"flex"}>
              <Box width={"23em"}>
                <Field
                  component={TextField}
                  style={{ width: "10em", marginRight: "1em" }}
                  variant="outlined"
                  margin="normal"
                  label={translate(`ipAddress snmp label`)}
                  name={this.ipAddressFieldId}
                  id={this.ipAddressFieldId}
                  data-cy={this.ipAddressFieldId}
                  values={values.ipAddress}
                  onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                    setFieldValue(this.ipAddressFieldId, e.target.value);
                    setFieldTouched(this.ipAddressFieldId, true, true);
                    handleChange(e);
                    validateField(this.ipAddressFieldId);
                    this.setState({ conflict: false });
                  }}
                />
                <Field
                  component={TextField}
                  style={{ width: "6em", padding: "0 1em" }}
                  variant="outlined"
                  margin="normal"
                  label={translate(`port snmp label`)}
                  name={this.portFieldId}
                  id={this.portFieldId}
                  data-cy={this.portFieldId}
                  values={values.port}
                  onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                    setFieldValue(this.portFieldId, e.target.value);
                    setFieldTouched(this.portFieldId, true, true);
                    handleChange(e);
                    validateField(this.portFieldId);
                    this.setState({ conflict: false });
                  }}
                />
              </Box>
              <Button
                type="submit"
                disabled={!this.canSubmit(errors, touched)}
                variant="contained"
                data-cy="snmp-settings-trap-receiver-create-button"
                style={{ width: "12em", margin: "1.25em 0 1.5em 1.5em" }}
                color="primary">
                {translate("Add")}
              </Button>
            </Box>
            <TrapReceiverFormErrors errors={errors} conflict={this.state.conflict} />
          </Form>
        )}
      </Formik>
    );
  }
}
