import React, { useState } from "react";
import { Box, FormControlLabel, Switch, Button, Typography, createStyles, makeStyles } from "@material-ui/core";
import RefreshIcon from "@material-ui/icons/Refresh";
import { useTranslation } from "react-i18next";
import useInterval from "use-interval";

const useStyles = makeStyles(_ =>
  createStyles({
    container: {
      width: "225px",
      padding: "0px 8px 8px 8px"
    },
    button: { marginRight: "1em" }
  })
);

interface IRefreshProps {
  refreshSearch: () => void;
  disabled: boolean;
}

export function Refresh(refreshProps: IRefreshProps): React.ReactElement {
  const { t } = useTranslation();
  const classes = useStyles();
  const [autoRefresh, setAutoRefresh] = useState(false);

  useInterval(
    () => {
      if (autoRefresh) {
        refreshProps.refreshSearch();
      }
    },
    //TODO reset the interval if the user change page, make a search, etc.
    10000
  );
  return (
    <Box display="flex" className={classes.container} flexDirection="column" justifyContent="center" alignItems="center">
      <Typography data-cy="refresh-label" variant="body2" color="textSecondary" style={{ fontSize: "10px" }}>
        {t("refresh")}
      </Typography>
      <Box display="flex">
        <Button
          onClick={refreshProps.refreshSearch}
          color="primary"
          variant="contained"
          className={classes.button}
          disabled={refreshProps.disabled}
          data-cy="refresh-button">
          <RefreshIcon fontSize="large" />
        </Button>

        <FormControlLabel
          control={
            <Switch
              value={autoRefresh}
              color="primary"
              onChange={() => {
                setAutoRefresh(!autoRefresh);
              }}
              checked={autoRefresh}
              data-cy="refresh-switch"
            />
          }
          label="Auto"
        />
      </Box>
    </Box>
  );
}
