import React, { SyntheticEvent } from "react";
import clsx from "clsx";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ErrorIcon from "@material-ui/icons/Error";
import InfoIcon from "@material-ui/icons/Info";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import WarningIcon from "@material-ui/icons/Warning";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { LED_COLORS } from "../utils/colors";

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon
};

const useNotificationStyle = makeStyles((theme: Theme) => ({
  success: {
    backgroundColor: LED_COLORS.OK.BACKGROUND.ENABLED,
    color: LED_COLORS.ERROR.FONT.ENABLED
  },
  error: {
    backgroundColor: LED_COLORS.ERROR.BACKGROUND.ENABLED,
    color: LED_COLORS.ERROR.FONT.ENABLED
  },
  info: {
    backgroundColor: LED_COLORS.OK.BACKGROUND.ENABLED,
    color: LED_COLORS.OK.FONT.ENABLED
  },
  warning: {
    backgroundColor: LED_COLORS.WARNING.BACKGROUND.ENABLED,
    color: LED_COLORS.OK.FONT.ENABLED
  },
  icon: {
    fontSize: 20
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1)
  },
  message: {
    display: "flex",
    alignItems: "left"
  }
}));

interface INotificationContentProps {
  className: string;
  message: string;
  onClose?: () => void;
  variant: keyof typeof variantIcon;
}

function NotificationContent(props: INotificationContentProps): React.ReactElement {
  const classes = useNotificationStyle();
  const { className, message, onClose, variant } = props;
  const Icon = variantIcon[variant];
  const classNames =
    variant === "success"
      ? clsx(classes["success"], className)
      : variant === "warning"
      ? clsx(classes["warning"], className)
      : clsx(classes["error"], className);

  return (
    <SnackbarContent
      data-cy="notification-popup"
      className={classNames}
      aria-describedby="client-snackbar"
      message={
        <span id="client-snackbar" className={className}>
          <Icon className={clsx(classes.icon, classes.iconVariant)} />
          {message}
        </span>
      }
      action={[
        <IconButton data-cy="notification-close-button" key="close" aria-label="close" color="inherit" onClick={onClose}>
          <CloseIcon className={classes.icon} />
        </IconButton>
      ]}
    />
  );
}

export interface INotificationProps {
  className: string;
  message: string;
}

const useStylesWrapper = makeStyles(theme => ({
  margin: {
    margin: theme.spacing(1)
  }
}));
export function Notification(notificationProps: INotificationProps): React.ReactElement {
  const [open, setOpen] = React.useState(true);

  const handleClose = (event?: SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(!open);
  };
  const classes = useStylesWrapper();
  const variant: any =
    notificationProps.className === "error" ? "error" : notificationProps.className === "warning" ? "warning" : "success";

  return (
    <NotificationContent onClose={handleClose} variant={variant} message={notificationProps.message} className={classes.margin} />
  );
}
