import React from "react";
import { Box, FormControl, InputLabel, Select, MenuItem } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { dropDownStyles } from "../../../utils/theme";

interface ILogPerPageProps {
  handleLogPerPageChange: (event: React.ChangeEvent<{ name?: string; value: unknown }>) => void;
  logPerPage: number;
}
export function LogPerPage(logPerPageProps: ILogPerPageProps): React.ReactElement {
  const { t } = useTranslation();
  const classes = dropDownStyles();
  return (
    <Box display="flex" alignItems="center" className={classes.container}>
      <FormControl className={classes.formControl}>
        <InputLabel className={classes.miniLabel} data-cy="log-per-page-label" htmlFor="logPerPage-select">
          {t("log per page")}
        </InputLabel>
        <Select
          onChange={logPerPageProps.handleLogPerPageChange}
          name="logPerPage"
          value={logPerPageProps.logPerPage}
          required
          inputProps={{
            name: "logPerPage",
            id: "logPerPage-select"
          }}>
          <MenuItem value={25} selected>
            25
          </MenuItem>
          <MenuItem value={50}>50</MenuItem>
          <MenuItem value={100}>100</MenuItem>
        </Select>
      </FormControl>
    </Box>
  );
}
