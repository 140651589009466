export const LICENSE_STATUS = {
  OK: "ok",
  WARNING: "warning",
  ERROR: "error"
};

export class License {
  public status: string | null;
  public expirationDate: Date | null;
  public qualityIndexEnable: boolean;

  public constructor() {
    this.status = null;
    this.expirationDate = null;
    this.qualityIndexEnable = false;
  }

  public isOk(): boolean {
    return this.status ? this.status === LICENSE_STATUS.OK : true;
  }
  public isWarning(): boolean {
    return this.status === LICENSE_STATUS.WARNING;
  }
  public isError(): boolean {
    return this.status === LICENSE_STATUS.ERROR;
  }
  public isNotOk(): boolean {
    return this.status !== LICENSE_STATUS.OK;
  }
}
