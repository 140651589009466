import React from "react";
import { Subscription } from "rxjs";

import { Route, Switch, BrowserRouter } from "react-router-dom";
import ReactNotifications, { store } from "react-notifications-component";

import { format } from "date-fns";
import { Box } from "@material-ui/core";

import { Login } from "./pages/Login";
import { Mosaic } from "./pages/Mosaic";
import { SoftwareLogs } from "./pages/SoftwareLogs";
import { Settings } from "./pages/Settings";
import { NetworkError } from "./pages/NetworkError";
import { Logout } from "./pages/Logout";
import { ChannelDetail } from "./pages/ChannelDetail";
import { Maintenance } from "./pages/Maintenance";

import { AcrAppBar } from "./components/Header/AcrAppBar";
import { Footer } from "./components/Footer/Footer";
import { Notification } from "./components/Notification";

import { acrAPI } from "./utils/api";
import { PrivateRoute } from "./components/PrivateRoute";
import { translate } from "i18n";
import { License } from "models/LicenseModel";

export const LICENSE_STATUS = {
  OK: "ok",
  WARNING: "warning",
  ERROR: "error",
};

export class ACRMonitor extends React.Component {
  public state = { licenseNotif: false };
  private licenseInterval = 0;
  private statusInterval = 0;

  private subscribtions: Subscription = new Subscription();

  public constructor(props: any) {
    super(props);
    this.initLicenseTimer();
    this.initStatusTimer();
  }
  public componentDidMount(): void {
    this.subscribtions.add(acrAPI.licenseSubject.subscribe(() => this.notifyLicenseStatus));
  }
  public componentWillUnmount(): void {
    this.subscribtions.unsubscribe();
  }

  private async initLicenseTimer(): Promise<void> {
    this.licenseInterval = 86400000;
    try {
      await acrAPI.getLicense();
    } catch (e) {
      console.log(`Error while getting license (init): ${e?.status} (${e?.statusText})`);
      this.licenseInterval = 4000;
    }
    this.triggerLicenseCustomInterval();
  }

  private triggerLicenseCustomInterval(): void {
    setTimeout(async () => {
      try {
        await acrAPI.getLicense();
      } catch (e) {
        console.log(`Error while getting license: ${e?.status} (${e?.statusText})`);
      }
      this.triggerLicenseCustomInterval();
    }, this.licenseInterval);
  }

  private async initStatusTimer(): Promise<void> {
    this.statusInterval = 4000;
    try {
      await acrAPI.getStatus();
    } catch (e) {
      console.log(`Error while getting status (init): ${e?.status} (${e?.statusText})`);
    }
    await this.triggerStatusTimer();
  }
  private async triggerStatusTimer(): Promise<void> {
    setTimeout(async () => {
      try {
        await acrAPI.getStatus();
      } catch (e) {
        console.log(`Error while getting status: ${e?.status} (${e?.statusText})`);
      }
      this.triggerStatusTimer();
    }, this.statusInterval);
  }

  private notifyLicenseStatus(license: License): void {
    let msg = "";
    let className = "";
    if (license.status === LICENSE_STATUS.WARNING) {
      className = "warning";
      msg = translate("license almost expired");
    }
    if (license.status === LICENSE_STATUS.ERROR) {
      className = "error";
      msg = translate("license invalid");
    }
    if (license.status !== LICENSE_STATUS.OK && !this.state.licenseNotif) {
      if (license.expirationDate) {
        msg += ` (${format(new Date(license.expirationDate), "yyyy/MM/dd")})`;
      }
      store.addNotification({
        type: { className },
        container: "bottom-right",
        insert: "top",
        content: <Notification className={className} message={msg} />,
        onRemoval: () => {
          this.setState({ licenseNotif: false });
        },
      });
      this.setState({ licenseNotif: true });
    }
  }

  public render(): JSX.Element {
    return (
      <BrowserRouter>
        <AcrAppBar />
        <Box className="acrMonitorBody">
          <Switch>
            <Route exact path="/networkError" component={NetworkError} />
            <Route exact path="/login" component={Login} />
            <PrivateRoute exact path="/logout" component={Logout} />
            <PrivateRoute exact path="/channelDetail/*" component={ChannelDetail} />
            <PrivateRoute exact path="/mosaic" component={Mosaic} />
            <PrivateRoute exact path="/" component={Mosaic} />
            <PrivateRoute exact path="/logs" component={SoftwareLogs} />
            <PrivateRoute exact path="/settings" component={Settings} />
            <PrivateRoute exact path="/maintenance" component={Maintenance} />
          </Switch>
        </Box>
        <Box display="flex" justifyContent="flex-end" className="notificationContainer">
          <ReactNotifications />
        </Box>
        <Footer />
      </BrowserRouter>
    );
  }
}
