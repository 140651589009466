import React from "react";
import { Subscription } from "rxjs";
import { Box, Typography } from "@material-ui/core";
import { translate } from "i18n";
import { AddNewTrapReceiver } from "./AddNewTrapReceiver";
import { TrapReceiverModel } from "acr-common-models";
import { TrapReceiver } from "./TrapReceiver";
import { acrAPI } from "utils/api";

export class TrapReceiversPanel extends React.Component {
  private subscribtions: Subscription = new Subscription();
  public state = { trapReceivers: new Array<TrapReceiverModel>() };
  constructor(props: any) {
    super(props);
    this.updateTrapReceivers = this.updateTrapReceivers.bind(this);
  }

  public componentDidMount(): void {
    this.subscribtions.add(acrAPI.trapReceiversSubject.subscribe(this.updateTrapReceivers));
  }

  public componentWillUnmount(): void {
    this.subscribtions.unsubscribe();
  }

  private updateTrapReceivers(trapReceivers: TrapReceiverModel[]): void {
    this.setState({ trapReceivers });
  }
  public render(): JSX.Element {
    return (
      <Box marginTop={1} marginBottom={2}>
        <Typography variant="h5" data-cy="settings-snmp-trap-receivers-title">
          {translate("Trap receivers")}
        </Typography>
        <Box marginTop={1}>
          <Typography variant="h6" data-cy="settings-snmp-add-trap-receiver-title">
            {translate("Add a trap receiver")}
          </Typography>
          <AddNewTrapReceiver trapReceivers={this.state.trapReceivers} />
        </Box>
        {this.state.trapReceivers.length === 0 ? (
          <Box marginTop={1}>
            <Typography color="textSecondary" data-cy="settings-snmp-no-trap-receiver-configured-label">
              {translate("No trap receiver configured yet.")}
            </Typography>
          </Box>
        ) : (
          <Box>
            <Typography variant="h6" data-cy="settings-snmp-add-trap-receiver-title">
              {translate("Trap Receiver List")}
            </Typography>
            {this.state.trapReceivers.map((trapReceiver) => (
              <TrapReceiver trapReceiver={trapReceiver} key={trapReceiver.id} />
            ))}
          </Box>
        )}
      </Box>
    );
  }
}
