import React from "react";
import { ThemeProvider } from "@material-ui/core/styles";
import { theme } from "./utils/theme";

import { ACRMonitor } from "./ACRMonitor";
import "./App.css";
import { acrAPI } from "./utils/api";
import { loginService } from "utils/login";

require("dotenv").config();

export class APP extends React.Component {
  constructor(props: any) {
    super(props);
    acrAPI.init();
    acrAPI.getVersion();
    loginService.init();
  }

  public render(): JSX.Element {
    return (
      <ThemeProvider theme={theme}>
        <ACRMonitor />
      </ThemeProvider>
    );
  }
}

export default APP;
