import React from "react";
import { Box, Paper, Typography } from "@material-ui/core";

import { ILedColors } from "../../utils/colors";

interface ILedProps {
  label?: number;
  colors: ILedColors;
  dataCy: string;
}

export class Led extends React.Component<ILedProps> {
  public render(): JSX.Element {
    const labelColor =
      this.props.label && this.props.label > 0 ? this.props.colors.FONT.ENABLED : this.props.colors.FONT.DISABLED;
    return (
      <Box display="flex" justifyItems="center" maxHeight="3rem">
        <Paper elevation={2}>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            bgcolor={this.props.colors.BACKGROUND.ENABLED}
            color={labelColor}
            data-cy={this.props.dataCy}
            //TODO style
            sx ={{
              minHeight: "2rem",
              minWidth: "2rem",
              maxHeight: "2rem",
              maxWidth: "2rem"
            }}>
            {this.props.label && <Typography variant="body2">{this.props.label}</Typography>}
          </Box>
        </Paper>
      </Box>
    );
  }
}
