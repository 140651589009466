import React from "react";
import { object, string, number } from "yup";
import { TrapReceiverModel } from "acr-common-models";
import { Formik, Form, Field } from "formik";
import { Box, TextField, Button } from "@material-ui/core";
import { translate } from "i18n";
import { acrAPI } from "utils/api";
import { Subscription } from "rxjs";
import { TrapReceiverFormErrors } from "./TrapReceiverFormErrors";
export const trapReceiverValidationSchema = object({
  port: number()
    .required(translate("a port number is required"))
    .min(1, translate("port value should be between 1 and 9999"))
    .max(9999, translate("port value should be between 1 and 9999")),
  ipAddress: string()
    .required(translate("an IP address is required"))
    .matches(
      /^(\d|[1-9]\d|1\d\d|2([0-4]\d|5[0-5]))\.(\d|[1-9]\d|1\d\d|2([0-4]\d|5[0-5]))\.(\d|[1-9]\d|1\d\d|2([0-4]\d|5[0-5]))\.(\d|[1-9]\d|1\d\d|2([0-4]\d|5[0-5]))$/,
      "Bad ip format"
    ),
});

interface ITrapReceiverProps {
  trapReceiver: TrapReceiverModel;
}

export class TrapReceiver extends React.Component<ITrapReceiverProps> {
  private dataCyPrefix: string;
  private subscribtions: Subscription = new Subscription();
  public state = { ipAddress: "", port: "162", id: 0, conflict: false };
  private ipAddressFieldId = `ipAddress`;
  private portFieldId = `port`;
  public constructor(props: any, private formId: string) {
    super(props);
    this.dataCyPrefix = `settings-snmp-${this.props.trapReceiver.id}`;
    this.formId = `${this.dataCyPrefix}-configuration-form`;
    this.updateTrapReceiver = this.updateTrapReceiver.bind(this);
  }
  public componentDidMount(): void {
    const { ipAddress, port, id } = this.props.trapReceiver;
    this.setState({ ipAddress, port, id });
    this.subscribtions.add(acrAPI.trapReceiversSubject.subscribe(this.updateTrapReceiver));
  }

  public componentWillUnmount(): void {
    this.subscribtions.unsubscribe();
  }
  private updateTrapReceiver(trapReceivers: Array<TrapReceiverModel>): void {
    const currentTrapReceiver = trapReceivers.find((trapReceiver) => trapReceiver.id === this.props.trapReceiver.id);
    this.setState({ ...currentTrapReceiver });
  }
  private async handleDelete(): Promise<void> {
    await acrAPI.deleteTrapReceiver(this.props.trapReceiver.id);
  }
  private async handleSubmit(values: any): Promise<void> {
    const { id, ipAddress, port } = values;
    const conflict = await acrAPI.updateTrapReceiver(this.props.trapReceiver.id, { id, ipAddress, port });
    this.setState({ conflict });
  }
  private canSubmit(errors: any, touched: any): boolean {
    return Object.keys(errors).length === 0 && Object.keys(touched).length > 0;
  }
  public render(): JSX.Element {
    return (
      <Formik
        key={this.formId}
        initialValues={this.state}
        enableReinitialize={true}
        validationSchema={trapReceiverValidationSchema}
        onSubmit={async (values, { resetForm }) => {
          await this.handleSubmit(values);
          resetForm({ values });
        }}>
        {({ handleSubmit, setFieldTouched, setFieldValue, touched, values, errors, validateField, handleChange }) => (
          <Form name={`${this.formId}-form`} key={`${this.formId}-form`} translate="none" method="POST" onSubmit={handleSubmit}>
            <Box>
              <Box display="flex">
                <Box width={"23em"}>
                  <Field
                    component={TextField}
                    data-cy="snmp-settings-trap-receiver-create-button"
                    variant="outlined"
                    margin="normal"
                    style={{ width: "10em", marginRight: "1em", marginBottom: "0" }}
                    label={translate(`ipAddress snmp label`)}
                    name={this.ipAddressFieldId}
                    id={this.ipAddressFieldId}
                    key={this.ipAddressFieldId}
                    onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                      setFieldValue(this.ipAddressFieldId, e.target.value);
                      setFieldTouched(this.ipAddressFieldId, true, true);
                      handleChange(e);
                      validateField(this.ipAddressFieldId);
                    }}
                    value={values.ipAddress}
                  />
                  <Field
                    component={TextField}
                    style={{ width: "6em", padding: "0 1em" }}
                    variant="outlined"
                    margin="normal"
                    label={translate(`port snmp label`)}
                    name={this.portFieldId}
                    id={this.portFieldId}
                    key={this.portFieldId}
                    data-cy={this.portFieldId}
                    onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                      setFieldValue(this.portFieldId, e.target.value);
                      setFieldTouched(this.portFieldId, true, true);
                      handleChange(e);
                      validateField(this.portFieldId);
                    }}
                    value={values.port}
                  />
                </Box>
                <Button
                  type="submit"
                  disabled={!this.canSubmit(errors, touched)}
                  variant="contained"
                  data-cy={`${this.dataCyPrefix}-save-button`}
                  style={{ margin: "1.25em 0 0.25em 1.5em", width: "12em", height: "2.5em", padding: "0 1em" }}
                  color="primary">
                  {translate("save")}
                </Button>
                <Button
                  onClick={() => this.handleDelete()}
                  variant="contained"
                  data-cy={`${this.dataCyPrefix}-delete-button`}
                  style={{ margin: "1.25em 0 0.25em 0.5em", height: "2.5em" }}
                  color="secondary">
                  {translate("delete")}
                </Button>
              </Box>
              <TrapReceiverFormErrors errors={errors} conflict={this.state.conflict} />
            </Box>
          </Form>
        )}
      </Formik>
    );
  }
}
