import React from "react";
import { Subscription } from "rxjs";
import { Formik, Form, Field, FormikTouched } from "formik";
import { Typography, TextField, Button, Box } from "@material-ui/core";
import { object, string, number } from "yup";
import { translate } from "i18n";
import { acrAPI } from "utils/api";

import { SmtpConfigModel } from "acr-common-models";

export class SmtpConfig extends React.Component { 

  private subscribtions: Subscription = new Subscription();
  private validationSchema = object({
    smtpName: string()
      .required(translate("SMTP hostname is required")),
    port: number()
      .required(translate("a port number is required"))
      .min(1, translate("port value should be between 1 and 9999"))
      .max(9999, translate("port value should be between 1 and 9999")),
    password: string(),
      //.required(translate("password is required")),
    emailSender: string()
      //.required(translate("an email address is required"))
      .matches(/^\S+@\S+\.\S+$/, translate("bad email format")),
  });

  public state = {smtpName: "Loading...", port: 0, password: "Loading...", emailSender: "Loading...", conflict: false};

  constructor(props: any) {
    super(props);
    this.updateSmtpConfig = this.updateSmtpConfig.bind(this);
  }

  public componentDidMount(): void {
    this.setState({ smtpName: "Loading...", port: 0, password: "Loading...", emailSender: "Loading...", conflict: false});
    this.subscribtions.add(acrAPI.smtpConfigSubject.subscribe(this.updateSmtpConfig));
    //acrAPI.getEmailConfiguration();
  }

  public componentWillUnmount(): void {
    this.subscribtions.unsubscribe();
  }

  private updateSmtpConfig(smtpConfigModel: SmtpConfigModel): void {
    this.setState({ ...smtpConfigModel });
  }

  private canSubmit(errors: any, touched: FormikTouched<SmtpConfigModel>): boolean {
    return Object.keys(errors).length === 0 && Object.keys(touched).length > 0;
  }

  private async handleSubmit(values: any): Promise<void> {

    const { smtpName, port, password, emailSender, id } = values;
    await acrAPI.updateSmtpConfiguration({ smtpName, port, password, emailSender, id });
  }

  public render(): JSX.Element {
    return (
      <Formik
        initialValues={this.state}
        enableReinitialize={true}
        validationSchema={this.validationSchema}
        onSubmit={async (values, { resetForm }) => {
          await this.handleSubmit(values);
          resetForm({ values });
        }}>
        {({ handleSubmit, handleChange, setFieldTouched, touched, values, errors }) => (
          <Form name="settings-email-configuration-form" translate="none" method="POST" onSubmit={handleSubmit}>
            <Box>
              <Typography variant="h5" data-cy="settings-email-configuration-form-title">
                {translate("senderEmailConfiguration")}
              </Typography>
              <Box display="flex" style={{ width: "40em", marginBottom: "0" }}>
                <Field
                  component={TextField}
                  variant="outlined"
                  margin="normal"
                  id="smtpName"
                  name="smtpName"
                  label={translate("smtpName label")}
                  maxLength={50}
                  style={{ width: "18em", marginRight: "2em" }}
                  helperText={errors.smtpName}
                  onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                    setFieldTouched("smtpName", true);
                    handleChange(e);
                  }}
                  value={values.smtpName}
                  data-cy={`email-smtp-name`}
                />

                <Field
                  component={TextField}
                  variant="outlined"
                  margin="normal"
                  id="port"
                  name="port"
                  label={translate("Port")}
                  style={{ width: "18em", marginRight: "0em" }}
                  helperText={errors.port}
                  onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                    setFieldTouched("port", true);
                    handleChange(e);
                  }}
                  value={values.port}
                  data-cy={`email-smtp-port`}
                />
              </Box>

              <Box display="flex" style={{ width: "40em", marginRight: "0em", marginBottom: "0" }}>
                <Field
                  component={TextField}
                  variant="outlined"
                  margin="normal"
                  id="emailSender"
                  name="emailSender"
                  label="your-email@gmail.com"
                  style={{ width: "18em", marginRight: "2em"}}
                  helperText={errors.emailSender}
                  onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                    setFieldTouched("emailSender", true);
                    handleChange(e);
                  }}
                  value={values.emailSender}
                  data-cy={`email-smtp-emailSender`}
                />
                <Field
                  type={"password"}
                  component={TextField}
                  variant="outlined"
                  margin="normal"
                  id="password"
                  name="password"
                  label={translate("password")}
                  style={{ width: "18em", marginRight: "0em" }}
                  helperText={errors.password}
                  onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                    setFieldTouched("password", true);
                    handleChange(e);
                  }}
                  value={values.password}
                  data-cy={`email-smtp-password`}
                />
              </Box>

              <Box display="flex" style={{ width: "40em", marginRight: "0em", marginBottom: "0" }}>
                <Box display="flex" style={{ width: "18em" ,marginRight: "2em" }}>
                </Box>
                
                <Button
                  type="submit"
                  disabled={!this.canSubmit(errors, touched)}
                  variant="contained"
                  data-cy="email-settings-configuration-save-button"
                  style={{ width: "15.5em", margin: "1em 0em 0em 0em" }}
                  color="primary">
                  {translate("save")}
                </Button>

              </Box>
            </Box>
          </Form>
        )}
      </Formik>
    );
  }
}
