import React, { RefObject } from "react";
import { RootRef, Paper, Typography, Box } from "@material-ui/core";

import { QualityIndexLog } from "acr-common-models";
import { WatermarkLog } from "models/WatermarkLogModel";

import Pagination from "../Header/Pagination";
import { LogPerPage } from "../Header/LogPerPage";
import { Download } from "../Header/Download";
import { Refresh } from "../Header/Refresh";
import { translate } from "i18n";
import { acrAPI } from "utils/api";
import { LogTable } from "./LogTable";
import { getFormatedUtcDate } from "utils/date";
import { ILogColumn } from "./LogTable";

import {
  capitalize,
  convertAndCapitalize,
  formatResampling,
  getWatermarkTimeStamp,
  getPayloadTypeTxt,
  formatWatermarkId,
} from "utils/log";

interface ILogsContainer {
  channelId: number;
  logType: typeof QualityIndexLog | typeof WatermarkLog;
  apiPrefix: "watermarkLog" | "qualityIndexLog";
}

interface ILogTableContainerState {
  pageNumber: number | "";
  totalPageCount: number;
  logPerPage: number;
  loading: boolean;
  logCount: number;
  logs: Array<QualityIndexLog | WatermarkLog>;
  containerHeight: number;
}
export class LogTableContainer extends React.Component<ILogsContainer> {
  private channelId: number;
  private headerRef: RefObject<HTMLDivElement>;
  private topRef: RefObject<HTMLDivElement>;

  public state = {
    pageNumber: 1,
    totalPageCount: 1,
    logPerPage: 25,
    loading: false,
    logCount: 0,
    logs: new Array<QualityIndexLog | WatermarkLog>(),
    containerHeight: 700,
  } as ILogTableContainerState;
  private columns: Array<ILogColumn>;

  constructor(props: any) {
    super(props);
    this.channelId = props.channelId;
    this.topRef = React.createRef();
    this.headerRef = React.createRef();
    this.columns = new Array<ILogColumn>();
    if (this.props.logType === QualityIndexLog) {
      this.columns.push(
        { cellName: "date", formatFunction: getFormatedUtcDate },
        { cellName: "status", formatFunction: capitalize },
        { cellName: "watermarkEnergy", formatFunction: capitalize },
        { cellName: "discontinuity", formatFunction: convertAndCapitalize },
        { cellName: "resampling", formatFunction: formatResampling },
        { cellName: "notWatermarkedAudio", formatFunction: convertAndCapitalize },
        { cellName: "signalEnergy", formatFunction: capitalize }
      );
    } else {
      this.columns.push(
        { cellName: "start", formatFunction: getWatermarkTimeStamp },
        { cellName: "stop", formatFunction: getWatermarkTimeStamp },
        { cellName: "payloadType", formatFunction: getPayloadTypeTxt },
        { cellName: "watermarkId", formatFunction: formatWatermarkId },
        { cellName: "timestamp", formatFunction: getWatermarkTimeStamp }
      );
    }
  }
  public componentDidMount(): void {
    this.getLogs(this.state.logPerPage, 1);
    this.handleResize();
    window.addEventListener("resize", () => this.handleResize());
  }

  public componentWillUnmount(): void {
    window.removeEventListener("resize", () => this.handleResize());
  }

  private handleResize(): void {
    if (this.headerRef?.current && this.topRef?.current) {
      if (this.topRef?.current.parentElement?.parentElement?.parentElement) {
        const headerHeight: number = this.headerRef.current.getBoundingClientRect().height || 80;
        const pageHeight: number = this.topRef.current.parentElement.parentElement.parentElement.getBoundingClientRect().height;
        this.setState({ containerHeight: pageHeight - (125 + headerHeight) });
      }
    }
  }

  private async getLogs(logPerPage: number, currentPageNumber: number | ""): Promise<void> {
    const apiPageNumber = typeof currentPageNumber === "number" ? currentPageNumber : 1;
    this.setState({ loading: false });
    try {
      await acrAPI.getLogs(this.props.apiPrefix, this.channelId, logPerPage, apiPageNumber).then((response) => {
        this.setState({
          logs: response.logs,
          totalPageCount: Math.floor(response.totalLogCount / this.state.logPerPage) + 1,
          loading: false,
        });
      });
    } catch (e) {
      console.log(`Error with getLogs, check if channel is available : ${e?.status} (${e?.statusText})`);
    }
  } 

  private handlePreviousPage(): void {
    if (this.state.pageNumber) {
      const currentPageNumber = this.state.pageNumber - 1;
      this.setState({ pageNumber: currentPageNumber });
      this.getLogs(this.state.logPerPage, currentPageNumber);
    }
  }
  private handleNextPage(): void {
    if (this.state.pageNumber) {
      const currentPageNumber = this.state.pageNumber + 1;
      this.setState({ pageNumber: currentPageNumber });
      this.getLogs(this.state.logPerPage, currentPageNumber);
    } else {
      this.setState({ pageNumber: 1 });
      this.getLogs(this.state.logPerPage, 1);
    }
  }
  private async handlePageNumberChange(event: React.ChangeEvent<{ name?: string; value: unknown }>): Promise<void> {
    let value: string = event.target.value as string;
    let numValue: number | "" = Number(value.replace(/[^0-9]/g, ""));
    if (numValue > this.state.totalPageCount) {
      numValue = this.state.totalPageCount;
    } else if (numValue <= 0) {
      numValue = "";
    }
    this.setState({ pageNumber: numValue });
  }

  private onPaginationKeyUp(event: React.KeyboardEvent<HTMLDivElement>): void {
    if (event.keyCode === 13) {
      const pageNumber = this.state.pageNumber > 0 ? this.state.pageNumber : 1;
      this.setState({ pageNumber });
      this.getLogs(this.state.logPerPage, pageNumber);
    }
  }
  private async refresh(): Promise<void> {
    if (this.state.pageNumber === "") {
      this.setState({ pageNumber: 1 });
    }
    await this.getLogs(this.state.logPerPage, this.state.pageNumber || 1);
  }
  private handleLogPerPageChange(event: React.ChangeEvent<{ name?: string; value: unknown }>): void {
    const value: number = event.target.value as number;
    const totalLogPage = Math.floor(this.state.logCount / value) + 1;
    this.setState({ logPerPage: value });
    if (this.state.pageNumber && this.state.logCount / value > this.state.pageNumber) {
      this.setState({ logCount: totalLogPage });
    }
    const currentLogPage = this.state.pageNumber > totalLogPage ? totalLogPage : this.state.pageNumber;
    this.setState({ pageNumber: currentLogPage });
    this.getLogs(value, currentLogPage);
  }

  public render(): JSX.Element {
    return (
      <RootRef rootRef={this.topRef}>
        <Paper elevation={4}>
          <RootRef rootRef={this.headerRef}>
            <>
              <Box
                //TODO STYLE
                style={{
                  opacity: 1,
                  overflow: "auto",
                  paddingLeft: "16px",
                }}
                flexWrap="wrap"
                display="flex"
                alignItems="center">
                <Pagination
                  handlePreviousPage={() => this.handlePreviousPage()}
                  handlePageNumberChange={(e) => this.handlePageNumberChange(e)}
                  handleNextPage={() => this.handleNextPage()}
                  pageNumber={this.state.pageNumber}
                  totalPageCount={this.state.totalPageCount}
                  onPaginationKeyUp={(e) => this.onPaginationKeyUp(e)}
                />
                <Box style={{ marginTop: "16px" }} display="flex">
                  <LogPerPage handleLogPerPageChange={(e) => this.handleLogPerPageChange(e)} logPerPage={this.state.logPerPage} />
                  <Refresh refreshSearch={() => this.refresh()} disabled={this.state.loading} />
                </Box>
                <Download logType={this.props.apiPrefix} channelId={this.channelId} />
              </Box>

              <Box style={{ overflow: "auto" }}>
                {this.state.loading ? (
                  <Box display="flex" justifyContent="center" alignItems="center" m={5} p={5}>
                    <Typography variant="h4">{translate("loading")}</Typography>
                  </Box>
                ) : this.state.logs.length > 0 ? (
                  <LogTable columns={this.columns} logs={this.state.logs} containerHeight={this.state.containerHeight} />
                ) : (
                  <Box display="flex" justifyContent="center" alignItems="center" m={5} p={5}>
                    <Typography variant="h4" data-cy="wmk-logs-no-log-label">
                      {translate("no log found")}
                    </Typography>
                  </Box>
                )}
              </Box>
            </>
          </RootRef>
        </Paper>
      </RootRef>
    );
  }
}
