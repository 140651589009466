import React from "react";
import { object, string } from "yup";
import { EmailReceiverModel } from "acr-common-models";
import { Formik, Form, Field } from "formik";
import { Box, TextField, Button } from "@material-ui/core";
import { translate } from "i18n";
import { acrAPI } from "utils/api";
import { Subscription } from "rxjs";
import { EmailReceiverFormErrors } from "./EmailReceiverFormErrors";
export const emailReceiverValidationSchema = object({
  emailAddress: string()
    .required(translate("an email address is required"))
    .matches(/^\S+@\S+\.\S+$/, translate("bad email format")),
});

interface IEmailReceiverProps {
  emailReceiver: EmailReceiverModel;
}

export class EmailReceiver extends React.Component<IEmailReceiverProps> {
  private dataCyPrefix: string;
  private subscribtions: Subscription = new Subscription();
  public state = { emailAddress: "", id: 0, conflict: false, errorNbEmail: false };
  private emailAddressFieldId = `emailAddress`;
  public constructor(props: any, private formId: string) {
    super(props);
    this.dataCyPrefix = `settings-email-${this.props.emailReceiver.id}`;
    this.formId = `${this.dataCyPrefix}-configuration-form`;
    this.updateEmailReceiver = this.updateEmailReceiver.bind(this);
  }
  public componentDidMount(): void {
    const { emailAddress, id } = this.props.emailReceiver;
    this.setState({ emailAddress, id });
    this.subscribtions.add(acrAPI.emailReceiversSubject.subscribe(this.updateEmailReceiver));
  }

  public componentWillUnmount(): void {
    this.subscribtions.unsubscribe();
  }
  private updateEmailReceiver(emailReceivers: Array<EmailReceiverModel>): void {
    const currentEmailReceiver = emailReceivers.find((emailReceiver) => emailReceiver.id === this.props.emailReceiver.id);
    this.setState({ ...currentEmailReceiver });
  }
  private async handleDelete(): Promise<void> {
    await acrAPI.deleteEmailReceiver(this.props.emailReceiver.id);
  }
  private async handleSubmit(values: any): Promise<void> {
    const { id, emailAddress } = values;
    const conflict = await acrAPI.updateEmailReceiver(this.props.emailReceiver.id, { id, emailAddress });
    const errorNbMail = await acrAPI.getEmailConfiguration();
    this.setState({ conflict, errorNbMail });
  }
  private canSubmit(errors: any, touched: any): boolean {
    return Object.keys(errors).length === 0 && Object.keys(touched).length > 0;
  }
  public render(): JSX.Element {
    return (
      <Formik
        key={this.formId}
        initialValues={this.state}
        enableReinitialize={true}
        validationSchema={emailReceiverValidationSchema}
        onSubmit={async (values, { resetForm }) => {
          await this.handleSubmit(values);
          resetForm({ values });
        }}>
        {({ handleSubmit, setFieldTouched, setFieldValue, touched, values, errors, validateField, handleChange }) => (
          <Form name={`${this.formId}-form`} key={`${this.formId}-form`} translate="none" method="POST" onSubmit={handleSubmit}>
            <Box>
              <Box display="flex">
                <Box width={"40em"}>
                  <Field
                    component={TextField}
                    data-cy="email-settings-receiver-create-button"
                    variant="outlined"
                    margin="normal"
                    style={{ width: "18em", marginRight: "2em", marginBottom: "0" }}
                    label="Your email address"
                    name={this.emailAddressFieldId}
                    id={this.emailAddressFieldId}
                    key={this.emailAddressFieldId}
                    onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                      setFieldValue(this.emailAddressFieldId, e.target.value);
                      setFieldTouched(this.emailAddressFieldId, true, true);
                      handleChange(e);
                      validateField(this.emailAddressFieldId);
                    }}
                    value={values.emailAddress}
                  />


                
                <Button
                  type="submit"
                  disabled={!this.canSubmit(errors, touched)}
                  variant="contained"
                  data-cy={`${this.dataCyPrefix}-save-button`}
                  style={{ width: "8.5em", margin: "1.25em 0.5em 0em 0em" }}
                  color="primary">
                  {translate("save")}
                </Button>
                <Button
                  onClick={() => this.handleDelete()}
                  variant="contained"
                  data-cy={`${this.dataCyPrefix}-delete-button`}
                  style={{ width: "6.5em", margin: "1.25em 0em 0em 0em" }}
                  color="secondary">
                  {translate("delete")}
                </Button>
              </Box>
              </Box>
              <EmailReceiverFormErrors errors={errors} conflict={this.state.conflict} errorNbEmail={this.state.errorNbEmail} />
            </Box>
          </Form>
        )}
      </Formik>
    );
  }
}
