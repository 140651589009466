import React from "react";
import { Box, Typography } from "@material-ui/core";
import { acrAPI } from "../../utils/api";
import { Subscription } from "rxjs";

export default class Clock extends React.Component {
  private intervalId? = 0;

  private subscribtions: Subscription = new Subscription();

  public state = {
    date: new Date()
  };

  constructor(props: any) {
    super(props);
    this.updateDate = this.updateDate.bind(this);
  }

  public componentDidMount(): void {
    this.subscribtions.add(acrAPI.serverDateSubject.subscribe(this.updateDate));
    this.startClockTimer();
  }

  public componentWillUnmount(): void {
    this.subscribtions.unsubscribe();
    this.stopClockTimer();
  }

  private updateDate(value: Date): void {
    this.setState({ date: value });
    this.startClockTimer();
  }

  private startClockTimer(): void {
    this.stopClockTimer();
    this.intervalId = window.setInterval(() => this.increaseClockSec(), 1000);
  }

  private stopClockTimer(): void {
    if (this.intervalId) {
      window.clearInterval(this.intervalId);
      this.intervalId = undefined;
    }
  }

  private increaseClockSec(): void {
    let newDate = new Date(this.state.date);
    newDate.setSeconds(this.state.date.getSeconds() + 1);
    this.setState({ date: newDate });
  }

  private pad(d: number): String {
    let r = String(d);
    if (r.length === 1) {
      r = "0" + r;
    }
    return r;
  }

  private getUTCClockLabel(): string {
    const d = this.state.date;
    const utcStr = `${this.pad(d.getUTCHours())}-${this.pad(d.getUTCMinutes())}-${this.pad(d.getUTCSeconds())}`;
    return utcStr;
  }

  public render(): JSX.Element {
    return (
      <Box display="flex" alignContent="center">
        <Typography data-cy="clock-utc-label" variant="body2" color="textSecondary">
          UTC:&nbsp;{this.getUTCClockLabel()}
        </Typography>
      </Box>
    );
  }
}
