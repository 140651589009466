import React from "react";
import { Popover, Button, Typography } from "@material-ui/core";

interface IAcrPopOverProps {
  icon: any;
  anchor?: string | null;
  content: any;
  buttonDataCy: string;
  labelDataCy: string;
}
export function AcrPopOver(acrPopOverProps: IAcrPopOverProps) {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <Button onClick={handleClick} data-cy={acrPopOverProps.buttonDataCy}>
        {acrPopOverProps.icon}
        <Typography variant="body1" color="textSecondary" data-cy={acrPopOverProps.labelDataCy}>
          {acrPopOverProps.anchor}
        </Typography>
      </Button>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center"
        }}
      >
        {acrPopOverProps.content}
      </Popover>
    </>
  );
}
