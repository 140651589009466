import React from "react";
import Paper from "@material-ui/core/Paper";
import { Box, AppBar, Tabs, Tab } from "@material-ui/core";
import { LicenseInformation } from "components/Maintenance/LicenseInformation";
import { PurgeLogs } from "components/Maintenance/PurgeLogs";
import { RestartACR } from "components/Maintenance/RestartACR";
import { translate } from "i18n";
import { SimpleTabPanel } from "components/Header/SimpleTabPanel";

export class Maintenance extends React.Component {
  public state = { tab: 0 };
  constructor(props: any) {
    super(props);
    window.onpopstate = () => {
      window.location.href = "/maintenance";
    };
  }
  //history manangement, we push this page in order to go back to the mosaic page
  //Note: we can acess to this current page only if we are on the mosaic and if we click on the + button
  private a11yProps(index: any): object {
    return {
      "id": `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  private tabChange = (event: React.ChangeEvent<{}>, tabId: number) => {
    this.setState({ tab: tabId });
  };
  public render(): JSX.Element {
    return (
      <Box style={{ margin: "1em 2em", width: "100%" }}>
        <Paper elevation={4}>
          <Box>
            <AppBar position="static">
              <Tabs value={this.state.tab} onChange={this.tabChange}>
                <Tab
                  data-cy="maintenance-license-information-tab"
                  label={translate("License information")}
                  {...this.a11yProps(0)}
                />
                <Tab data-cy="maintenance-purge-logs-tab" label={translate("Logs")} {...this.a11yProps(1)} />
                <Tab data-cy="maintenance-restart-acr-tab" label={translate("Server")} {...this.a11yProps(2)} />
              </Tabs>
            </AppBar>
          </Box>
          <Box margin={2} p={2} display="flex" flexDirection="column">
            <SimpleTabPanel value={this.state.tab} index={0}>
              <LicenseInformation />
            </SimpleTabPanel>
            <SimpleTabPanel value={this.state.tab} index={1}>
              <PurgeLogs />
            </SimpleTabPanel>
            <SimpleTabPanel value={this.state.tab} index={2}>
              <RestartACR />
            </SimpleTabPanel>
          </Box>
        </Paper>
      </Box>
    );
  }
}
