import React from "react";
import axios from "axios";
import { Box, Typography, Button } from "@material-ui/core";

import { translate } from "../../i18n";
import { CustomDialog } from "components/CustomDialog";

export class RestartACR extends React.Component {
  public state = { dialogOpen: false };
  private openRestartConfirmation(): void {
    this.setState({ dialogOpen: true });
  }
  private closeRestartConfirmation(): void {
    this.setState({ dialogOpen: false });
  }
  private async restart(): Promise<void> {
    axios.get(`/maintenance/restart`).then((res) => {
      this.setState({ dialogOpen: false });
    });
  }
  public render(): JSX.Element {
    return (
      <>
        <CustomDialog
          title={translate("Restart confirmation logs title")}
          message={translate("Restart confirmation logs text")}
          action={() => this.restart()}
          open={this.state.dialogOpen}
          disableAction={false}
          handleClose={() => this.closeRestartConfirmation()}
          confirmLabel={"restart"}
        />
        <Box display="flex" flexDirection="column">
          <Typography variant="h5" data-cy="maintenance-restart-title">
            {translate("Restart title")}
          </Typography>
          <Box display="flex" alignItems="center">
            <Typography variant="h6" data-cy="maintenance-restart-text">
              {translate("Restart explanation")}
            </Typography>
            <Button
              data-cy="maintenance-restart-button"
              style={{ margin: "1em" }}
              variant="contained"
              color="primary"
              onClick={() => this.openRestartConfirmation()}>
              {translate("restart")}
            </Button>
          </Box>
        </Box>
      </>
    );
  }
}
